import React from "react";
import { Col, Layout, Row } from "antd";
import HeaderComponent from "./layout/header.component";
import FooterComponent from "./layout/footer.component";
import DelegateModule from "./modules/delegate.module";
import "../styles/delegate.less";

const { Content } = Layout;

function DelegateComponent() {
  return (
    <Layout className="layout page-delegate">
      <HeaderComponent />
      <Content>
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <DelegateModule />
          </Col>
        </Row>
      </Content>
      <FooterComponent />
    </Layout>
  );
}

export default DelegateComponent;
