import React from "react";
import { Col, Layout, Row } from "antd";
import HeaderComponent from "./layout/header.component";
import FooterComponent from "./layout/footer.component";
import RewardsModule from "./modules/rewards.module";
import "../styles/rewards.less";

const { Content } = Layout;

function RewardsComponent() {
  return (
    <Layout className="layout page-rewards">
      <HeaderComponent />
      <Content>
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <RewardsModule />
          </Col>
        </Row>
      </Content>
      <FooterComponent />
    </Layout>
  );
}

export default RewardsComponent;
