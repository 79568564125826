import React, { useState } from "react";
import { Button, Modal } from "antd";

import { useWeb3React } from "@web3-react/core";
import { connect } from "react-redux";
import { QrcodeOutlined } from "@ant-design/icons";

import QRCode from "react-qr-code";
import { AppState } from "../../redux/states/app";
import BalanceModule from "../modules/balance.module";
import "../../styles/widget/wallet.less";

interface Props {
  app: AppState;
  showQRCodeIcon: boolean;
}

function WalletWidget(props: Props) {
  const [showReceiveModal, setShowReceiveModal] = useState(false);
  const { isActive, account } = useWeb3React();

  return (
    <div className={"widget-container"}>
      <h2>
        My Balance
        {isActive && props.showQRCodeIcon && (
          <span onClick={() => setShowReceiveModal(true)} className={"widget-container-action-button"}>
            <QrcodeOutlined />
          </span>
        )}
      </h2>
      <BalanceModule />
      <Modal
        footer={[
          <Button key={"close-button"} onClick={() => setShowReceiveModal(false)}>
            Close
          </Button>,
        ]}
        open={showReceiveModal}
        title={"My Account"}
        onCancel={() => setShowReceiveModal(false)}
      >
        <div id={"qr-code-value"}>{account && <QRCode value={account} />}</div>
        <div id={"qr-code-title"}>{account}</div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  app: state.app,
});

export default connect(mapStateToProps)(WalletWidget);
