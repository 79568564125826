import React from "react";
import { Layout } from "antd";
import moment from "moment";

const { Footer } = Layout;

function FooterComponent() {
  return (
    <Footer style={{ textAlign: "center" }}>Delegation App &copy;{moment().format("YYYY")} | Created by The Grungies</Footer>
  );
}

export default FooterComponent;
