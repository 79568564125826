import React, { useEffect } from "react";
import { ConfigProvider } from "antd";
import enGB from "antd/es/locale/en_GB";
import { errorBoundry } from "./components/app/error-boundary";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { useWeb3React } from "@web3-react/core";
import { AppState } from "./redux/states/app";
import { ethers } from "ethers";
import { connect } from "react-redux";

interface Props {
  app: AppState;
}

const App = (props: Props) => {
  const { connector, isActive } = useWeb3React();

  useEffect(() => {
    detectNetworkChange();
  }, [props.app.chainId]);

  useEffect(() => {
    if (connector?.connectEagerly) {
      connector.connectEagerly();
    }
  }, []);

  const detectNetworkChange = async () => {
    // @ts-ignore
    if (isActive && window.ethereum.networkVersion !== props.app.chainId) {
      try {
        // @ts-ignore
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: ethers.utils.hexValue(props.app.chainId) }],
        });
      } catch (err: any) {
        // This error code indicates that the chain has not been added to MetaMask
        if (err.code === 4902) {
          // @ts-ignore
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: props.app.network,
                chainId: ethers.utils.hexValue(props.app.chainId),
                nativeCurrency: { name: props.app.nat, decimals: 18, symbol: props.app.nat },
                rpcUrls: [props.app.rpcUrl],
              },
            ],
          });
        }
      }
    }
  };

  return (
    <ConfigProvider locale={enGB}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
});

export default connect(mapStateToProps)(errorBoundry(App));
