import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import { Contract, providers } from "ethers";
import { AppState } from "../../redux/states/app";
import { formatNumber, formatPrice } from "../../helpers/price.helper";
import { contracts } from "../../contracts";
import { connect } from "react-redux";
import { useWeb3React } from "@web3-react/core";

interface Props {
  app: AppState;
}

const NetworkStatsModule = (props: Props) => {
  const { provider } = useWeb3React();
  const [circulatingSupply, setCirculatingSupply] = useState(0);
  const [inflatableBalance, setInflatableBalance] = useState(0);
  const [inflationAuthorized, setInflationAuthorized] = useState(0);
  const [foundationSupply, setFoundationSupply] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const rpcProvider = new providers.WebSocketProvider(props.app.rpcUrl);
    const supplyContract = contracts[props.app.network].supply;
    const supplyManager = new Contract(supplyContract.address, supplyContract.abi, rpcProvider);

    setLoading(true);
    getNetworkData(supplyManager);
  }, [provider]);

  const getNetworkData = async (supplyManager: any) => {
    const inflatableBalance = await supplyManager.getInflatableBalance();
    const inflatableBalanceValue = parseInt(inflatableBalance.toString()) / 10e17;

    let blockNumber = await provider?.getBlockNumber();

    if (!provider) {
      const rpcProvider = new providers.WebSocketProvider(props.app.rpcUrl);
      blockNumber = await rpcProvider.getBlockNumber();
    }

    const circulatingSupply = await supplyManager.getCirculatingSupplyAt(blockNumber);

    const circulatingSupplyValue = parseInt(circulatingSupply.toString()) / 10e17;

    const inflationAuthorized = await supplyManager.totalInflationAuthorizedWei();
    const inflationAuthorizedValue = parseInt(inflationAuthorized.toString()) / 10e17;

    let foundationSupplyValue = 0;
    if (props.app.network === "songbird") {
      const foundationSupply = await supplyManager.totalFoundationSupplyWei();
      foundationSupplyValue = parseInt(foundationSupply.toString()) / 10e17;
    }

    setInflatableBalance(inflatableBalanceValue);
    setCirculatingSupply(circulatingSupplyValue);
    setInflationAuthorized(inflationAuthorizedValue);
    setFoundationSupply(foundationSupplyValue);
    setLoading(false);
  };

  return (
    <Row>
      <Col xs={24}>
        <div className={"widget-container"}>
          <Spin spinning={loading}>
            <Row id={"network-stats-row"} gutter={20}>
              <Col xs={24} sm={12} md={8} xl={4}>
                <div>
                  <strong>Circulating Supply: </strong>{" "}
                  <span className={"stats-value"}>{loading ? "-" : formatNumber(circulatingSupply)}</span>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} xl={4}>
                <div>
                  <strong>Inflatable Balance: </strong>{" "}
                  <span className={"stats-value"}>{loading ? "-" : formatNumber(inflatableBalance)}</span>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} xl={4}>
                <div>
                  <strong>Foundation Supply: </strong>{" "}
                  <span className={"stats-value"}>
                    {loading || foundationSupply === 0 ? "-" : formatNumber(foundationSupply)}
                  </span>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} xl={4}>
                <div>
                  <strong>Inflation Authorized: </strong>{" "}
                  <span className={"stats-value"}>{loading ? "-" : formatNumber(inflationAuthorized)}</span>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} xl={4}>
                <div>
                  <strong>Price {props.app.nat}: </strong>
                  {/* TODO: remove props.app.assets[props.app.nat] === undefined once FLR is live on Flare  */}
                  <span className={"stats-value"}>
                    {loading || props.app.assets[props.app.nat] === undefined
                      ? "-"
                      : formatPrice(props.app.assets[props.app.nat], props.app.currency.symbol, 5)}
                  </span>
                </div>
              </Col>
              <Col xs={24} sm={12} md={8} xl={4}>
                <div>
                  <strong>Market Cap: </strong>
                  {/* TODO: remove props.app.assets[props.app.nat] === undefined once FLR is live on Flare  */}
                  <span className={"stats-value"}>
                    {loading || props.app.assets[props.app.nat] === undefined ? (
                      "-"
                    ) : (
                      <span>${formatNumber(props.app.assets[props.app.nat] * circulatingSupply)}</span>
                    )}
                  </span>
                </div>
              </Col>
            </Row>
          </Spin>
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
});

export default connect(mapStateToProps)(NetworkStatsModule);
