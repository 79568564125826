import React, { useEffect, useState } from "react";
import { Avatar, Button, Col, Input, InputNumber, Layout, List, message, Row, Space, Spin } from "antd";
import HeaderComponent from "./layout/header.component";
import FooterComponent from "./layout/footer.component";
import { useWeb3React } from "@web3-react/core";
import { AppState } from "../redux/states/app";
import { connect } from "react-redux";
import { CalendarOutlined, MessageOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { truncateAddress } from "../helpers/address.helper";
import { Token, Transaction } from "../types";
import QRCode from "react-qr-code";
import WalletWidget from "./widgets/wallet.widget";
import { ethers } from "ethers";
import Swal from "sweetalert2";
import { handleTxError } from "../helpers/tx-error.helper";
import "../styles/wallet.less";

const { Content } = Layout;

interface Props {
  app: AppState;
}

function WalletComponent(props: Props) {
  const [loadingTxs, setLoadingTxs] = useState(false);
  const [loadingPendingTxs, setLoadingPendingTxs] = useState(false);
  const [loadingInternalTxs, setLoadingInternalTxs] = useState(false);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [pendingTransactions, setPendingTransactions] = useState<Transaction[]>([]);
  const [internalTransactions, setInternalTransactions] = useState<Transaction[]>([]);
  const [amount, setAmount] = useState<number | null>(null);
  const [balanceNAT, setBalanceNAT] = useState(0);
  const [receivingAccount, setReceivingAccount] = useState<string | undefined>(undefined);
  const [loadingNAT, setLoadingNAT] = useState(false);
  const [loadingTokens, setLoadingTokens] = useState(false);
  const [tokens, setTokens] = useState<Token[]>([]);
  const { isActive, account, provider } = useWeb3React();

  const IconText = ({ icon, text }: { icon: React.FC; text: any }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );

  useEffect(() => {
    if (isActive && account) {
      getBalanceNAT(account);
    } else {
      setBalanceNAT(0);
      setTokens([]);
      setTransactions([]);
      setPendingTransactions([]);
      setInternalTransactions([]);
    }
  }, [account, provider]);

  const getBalanceNAT = async (account: string) => {
    setLoadingNAT(true);
    if (provider?.getBalance) {
      const balance = await provider.getBalance(account);
      const balanceValue = parseInt(balance.toString()) / 1e18;
      setBalanceNAT(balanceValue);
    }
    setLoadingNAT(false);
  };

  const getFormattedAmount = () => {
    return `${balanceNAT} ${props.app.nat}`;
  };

  const fetchTransactions = () => {
    if (account) {
      setLoadingTxs(true);
      setLoadingPendingTxs(true);
      setLoadingPendingTxs(true);
      const apiUrl = "https://songbird-explorer.flare.network/api";
      fetch(apiUrl + "?module=account&page=0&offset=10&action=txlist&address=" + account)
        .then((res) => res.json())
        .then((data: { message: string; result: Transaction[]; status: string }) => {
          setTransactions(data.result);
          setLoadingTxs(false);
        })
        .catch((err) => {
          setLoadingTxs(false);
        });

      fetch(apiUrl + "?module=account&page=0&offset=10&action=pendingtxlist&address=" + account)
        .then((res) => res.json())
        .then((data: { message: string; result: Transaction[]; status: string }) => {
          setPendingTransactions(data.result);
          setLoadingPendingTxs(false);
        })
        .catch((err) => {
          setLoadingPendingTxs(false);
        });

      fetch(apiUrl + "?module=account&page=0&offset=10&action=txlistinternal&address=" + account)
        .then((res) => res.json())
        .then((data: { message: string; result: Transaction[]; status: string }) => {
          setInternalTransactions(data.result);
          setLoadingInternalTxs(false);
        })
        .catch((err) => {
          setLoadingInternalTxs(false);
        });

      fetch(apiUrl + "?module=account&action=tokenlist&address=" + account)
        .then((res) => res.json())
        .then((data: { message: string; result: Token[]; status: string }) => {
          setTokens(data.result);
          setLoadingTokens(false);
        })
        .catch((err) => {
          setLoadingTokens(false);
        });
    } else {
      setLoadingTxs(false);
      setLoadingPendingTxs(false);
      setLoadingPendingTxs(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [account, provider]);

  const allTxs = [...transactions, ...pendingTransactions, ...internalTransactions].sort((a, b) =>
    b.timeStamp > a.timeStamp ? 1 : -1
  );

  const sendTransaction = async () => {
    if (!amount) {
      Swal.fire({
        title: "Invalid Amount",
        icon: "warning",
        html: `Please enter an amount between <strong>0</strong> and <strong>${getFormattedAmount()}</strong>.`,
      });
    } else if (balanceNAT === 0) {
      Swal.fire({
        title: `Insufficient balance`,
        icon: "warning",
        html: `Your balance is 0`,
      });
    } else if (amount > balanceNAT) {
      Swal.fire({
        title: "Max Amount Exceeded",
        icon: "warning",
        html: `Please enter an amount between <strong>0</strong> and <strong>${getFormattedAmount()}</strong>.`,
      });
    } else if (!receivingAccount || receivingAccount.trim() === "") {
      Swal.fire({
        title: `Invalid receiving address`,
        icon: "warning",
        html: `Please enter a valid receiving address`,
      });
    } else if (provider && account) {
      const signer = provider.getSigner();
      const params = {
        from: account,
        to: receivingAccount,
        value: ethers.utils.parseEther(amount.toString()).toHexString(),
      };

      try {
        const userConfirmationMessage = message.loading("Waiting for your approval...", 0);
        const tx = await signer.sendTransaction(params);
        userConfirmationMessage();
        const networkConfirmationMessage = message.loading("Waiting for network confirmation...", 0);
        await tx.wait();
        networkConfirmationMessage();

        setReceivingAccount(undefined);
        setAmount(null);
        getBalanceNAT(account);
        fetchTransactions();

        Swal.fire({
          title: "Succesful!",
          icon: "success",
          html: `Successfully sent <strong>${amount} ${props.app.nat}</strong> to <strong>${truncateAddress(
            receivingAccount,
            6
          )}</strong>`,
        });
      } catch (e) {
        message.destroy();
        handleTxError(e);
      }
    }
  };

  const anyLoading = loadingTxs || loadingPendingTxs || loadingInternalTxs;

  return (
    <Layout className="layout page-wallet">
      <HeaderComponent />
      <Content>
        <Row justify={"center"} gutter={[20, 20]}>
          <Col xs={24} lg={20} xxl={14}>
            <Row gutter={[20, 20]}>
              <Col xs={24}>
                <WalletWidget key={balanceNAT} showQRCodeIcon={false} />
              </Col>
              <Col xs={24}>
                <Row gutter={[20, 20]}>
                  <Col xs={24} xl={12}>
                    <div style={{ height: "100%" }} className={"widget-container"}>
                      <h2>My Account</h2>
                      <Row style={{ height: "85%" }} align={"middle"} justify={"center"}>
                        <Col xs={20}>
                          {account && (
                            <div style={{ width: "100%", textAlign: "center", display: "inline-block" }}>
                              <QRCode size={200} value={account} />
                            </div>
                          )}
                          {account && <div style={{ color: "#aaa", textAlign: "center" }}>{account}</div>}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={24} xl={12}>
                    <Row gutter={[20, 20]}>
                      <Col xs={24}>
                        <div className={"widget-container"}>
                          <h2>Send</h2>
                          <Spin spinning={loadingNAT}>
                            <span
                              id={`max-value-button`}
                              className={isActive ? "active" : "disabled"}
                              onClick={() => (isActive ? setAmount(balanceNAT) : undefined)}
                            >
                              Max: {getFormattedAmount()}
                            </span>
                            <Space direction={"vertical"} style={{ width: "100%" }}>
                              <InputNumber
                                decimalSeparator={"."}
                                disabled={!isActive}
                                inputMode={"decimal"}
                                onChange={(e) => setAmount(e as number)}
                                addonAfter={props.app.nat}
                                placeholder={"Enter Amount..."}
                                style={{ width: "100%" }}
                                size={"large"}
                                value={amount}
                                min={0}
                                max={balanceNAT}
                              />
                              <Input
                                onChange={(e) => setReceivingAccount(e.target.value)}
                                disabled={!isActive}
                                allowClear={true}
                                size={"large"}
                                value={receivingAccount}
                                placeholder={"Receiving address..."}
                              />
                              <Button
                                onClick={() => sendTransaction()}
                                size={"large"}
                                disabled={!isActive}
                                block={true}
                                type={"primary"}
                              >
                                Send...
                              </Button>
                            </Space>
                          </Spin>
                        </div>
                      </Col>

                      <Col xs={24}>
                        <div className={"widget-container"}>
                          <h2>Other Tokens</h2>

                          {loadingTokens && <em>Loading Tokens...</em>}
                          {tokens.map((token, index) => {
                            return (
                              <div key={index} className={"other-token"}>
                                <strong>{token.name}: </strong>
                                <span style={{ float: "right" }}>
                                  {parseInt(token.balance) / 10 ** parseInt(token.decimals)} {token.symbol}
                                </span>
                              </div>
                            );
                          })}

                          {tokens.length === 0 && !loadingTokens && <em>There are no other tokens in your wallet</em>}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col xs={24} lg={20} xxl={14}>
            <div className={"widget-container"}>
              <h2>Transactions</h2>
              <Row gutter={[20, 20]}>
                <Col xs={24}>
                  <List
                    itemLayout={"vertical"}
                    className="demo-loadmore-list"
                    loading={anyLoading}
                    dataSource={allTxs}
                    renderItem={(item) => (
                      <List.Item
                        extra={
                          <div style={{ fontSize: "1.2rem" }}>
                            {parseInt(item.value) / 1e18} {props.app.nat}
                          </div>
                        }
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              src={
                                item.type === "call"
                                  ? require("../assets/smart-contracts.png")
                                  : item.from === account?.toLowerCase()
                                  ? require("../assets/outbox.png")
                                  : require("../assets/inbox.png")
                              }
                            />
                          }
                          title={
                            item.type === "call" ? "Contract Call" : item.from === account?.toLowerCase() ? "Sent" : "Receive"
                          }
                          description={
                            <Space>
                              <IconText
                                icon={CalendarOutlined}
                                text={moment.unix(parseInt(item.timeStamp)).utc().format("DD MMM YYYY HH:mm:ss")}
                                key="list-vertical-star-o"
                              />
                              <IconText
                                icon={UserOutlined}
                                text={
                                  item.to === account?.toLowerCase() ? truncateAddress(item.from, 6) : truncateAddress(item.to, 6)
                                }
                                key="list-vertical-like-o"
                              />
                              {item.hash && (
                                <IconText
                                  icon={MessageOutlined}
                                  text={
                                    <a
                                      rel={"noreferrer"}
                                      target={"_blank"}
                                      href={props.app.explorerUrl[props.app.network] + "/tx/" + item.hash}
                                    >
                                      {truncateAddress(item.hash, 6)}
                                    </a>
                                  }
                                  key="list-vertical-message"
                                />
                              )}
                            </Space>
                          }
                        />
                      </List.Item>
                    )}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Content>
      <FooterComponent />
    </Layout>
  );
}

const mapStateToProps = (state: any) => ({
  app: state.app,
});

export default connect(mapStateToProps)(WalletComponent);
