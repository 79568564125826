import React, { useEffect, useState } from "react";
import { Col, Layout, Row } from "antd";
import HeaderComponent from "./layout/header.component";
import FooterComponent from "./layout/footer.component";
import NetworkStatsComponent from "./modules/network-stats.module";
import AssetPricesComponent from "./modules/asset-prices.module";
import EpochStatsModule from "./modules/epoch-stats.module";
import RewardsValuesModule from "./modules/rewards-values.module";
import { Link } from "react-router-dom";
import WalletWidget from "./widgets/wallet.widget";
import { useWeb3React } from "@web3-react/core";
import { EpochRewardsData } from "../types";
import { getRewardsData } from "../helpers/rewards.helper";
import { AppState } from "../redux/states/app";
import { connect } from "react-redux";
import "../styles/home.less";

const { Content } = Layout;

interface Props {
  app: AppState;
}

function HomeComponent(props: Props) {
  const { isActive, account, provider } = useWeb3React();
  const [outstandingRewards, setOutStandingRewards] = useState<EpochRewardsData[]>([]);
  const [currentEpochRewards, setCurrentEpochRewards] = useState(0);
  const [loadingRewardsData, setLoadingRewardsData] = useState(false);

  useEffect(() => {
    if (isActive && account) {
      getData(account);
    } else {
      setOutStandingRewards([]);
      setCurrentEpochRewards(0);
    }
  }, [provider, isActive, account]);

  const getData = async (account: string) => {
    setLoadingRewardsData(true);
    const rewardsData = await getRewardsData(provider, props.app.network, account);
    setOutStandingRewards(rewardsData.pendingRewards);
    setCurrentEpochRewards(rewardsData.currentRewards);
    setLoadingRewardsData(false);
  };

  return (
    <Layout className="layout page-home">
      <HeaderComponent />
      <Content>
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <NetworkStatsComponent />
          </Col>

          <Col xs={24} lg={24} xl={12}>
            <div className={"widget-container"}>
              <h2>Epoch Stats</h2>
              <EpochStatsModule showPriceEpoch={true} />
            </div>
          </Col>

          <Col xs={24} lg={12} xl={6}>
            <WalletWidget showQRCodeIcon={true} />
          </Col>

          <Col xs={24} lg={12} xl={6}>
            <div className={"widget-container"}>
              <h2>
                My Rewards
                {isActive ? (
                  <Link to={"/rewards"}>
                    <span className={`widget-container-action-button`}>Claim</span>
                  </Link>
                ) : (
                  <span className={`widget-container-action-button disabled`}>Claim</span>
                )}
              </h2>
              <RewardsValuesModule
                loading={loadingRewardsData}
                outstandingRewards={outstandingRewards}
                currentEpochRewards={currentEpochRewards}
              />
            </div>
          </Col>

          <Col xs={24}>
            <div className={"widget-container"}>
              <h2>Asset Prices</h2>
              <AssetPricesComponent />
            </div>
          </Col>
        </Row>

        <Row id={"nft-row"} gutter={[50, 50]}>
          <Col xs={8}>
            <div className={"nft-container"}>
              <img src={"https://thegrungies.com/config/images/examplemh.gif"} />
            </div>
          </Col>
          <Col xs={8}>
            <div className={"nft-container"}>
              <img className={"nft-reverse"} src={"https://thegrungies.com/config/images/examplemh.gif"} />
            </div>
          </Col>
          <Col xs={8}>
            <div className={"nft-container"}>
              <img src={"https://thegrungies.com/config/images/examplemh.gif"} />
            </div>
          </Col>
        </Row>
      </Content>
      <FooterComponent />
    </Layout>
  );
}

const mapStateToProps = (state: any) => ({
  app: state.app,
});

export default connect(mapStateToProps)(HomeComponent);
