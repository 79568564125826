import {
  ftsoManagerSGB,
  ftsoRegistrySGB,
  ftsoRewardManagerSGB,
  priceFinalizedAbiSGB,
  supplySGB,
  VPContractSGB,
  wNatSGB,
} from "./songbird.contracts";
import {
  ftsoManagerFLR,
  ftsoRegistryFLR,
  ftsoRewardManagerFLR,
  priceFinalizedAbiFLR,
  supplyFLR,
  VPContractFLR,
  wNatFLR,
} from "./flare.contracts";
import { balanceOfToken } from "./custom.contracts";

export interface contractsInterface {
  flare: {
    ftsoManager: { address: string; abi: any };
    ftsoRewardManager: { address: string; abi: any };
    vpContract: { address: string; abi: any };
    ftsoRegistry: { address: string; abi: any };
    supply: { address: string; abi: any };
    priceFinalizedAbi: any;
    balanceContract: any[];
    wNatContract: { address: string; abi: any };
  };
  songbird: {
    ftsoManager: { address: string; abi: any };
    ftsoRewardManager: { address: string; abi: any };
    vpContract: { address: string; abi: any };
    ftsoRegistry: { address: string; abi: any };
    supply: { address: string; abi: any };
    priceFinalizedAbi: any;
    balanceContract: any[];
    wNatContract: { address: string; abi: any };
  };
}

export const contracts: contractsInterface = {
  flare: {
    ftsoManager: ftsoManagerFLR,
    ftsoRewardManager: ftsoRewardManagerFLR,
    vpContract: VPContractFLR,
    ftsoRegistry: ftsoRegistryFLR,
    supply: supplyFLR,
    priceFinalizedAbi: priceFinalizedAbiFLR,
    balanceContract: balanceOfToken,
    wNatContract: wNatFLR,
  },
  songbird: {
    ftsoManager: ftsoManagerSGB,
    ftsoRewardManager: ftsoRewardManagerSGB,
    vpContract: VPContractSGB,
    ftsoRegistry: ftsoRegistrySGB,
    supply: supplySGB,
    priceFinalizedAbi: priceFinalizedAbiSGB,
    balanceContract: balanceOfToken,
    wNatContract: wNatSGB,
  },
};
