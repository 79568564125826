export const formatPrice = (price: number, symbol: string, decimals = 2) => {
  return `${symbol}${price.toFixed(decimals).replace(".", ",")}`;
};

export const formatNumber = (num: number) => {
  const units = ["M", "B", "T", "Q"];
  const unit = Math.floor((num / 1.0e1).toFixed(0).toString().length);
  const r = unit % 3;
  const x = Math.abs(Number(num)) / parseFloat(Number("1.0e+" + (unit - r)).toFixed(2));
  return x.toFixed(2) + "" + units[Math.floor(unit / 3) - 2];
};
