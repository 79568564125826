import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import reducers from "./reducers";
import { createTransform, persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { parse, stringify } from "flatted";

const logger = createLogger({
  collapsed: true,
});

let middleware = [thunk, logger];

export const transformCircular = createTransform(
  (inboundState, key) => stringify(inboundState),
  (outboundState, key) => parse(outboundState)
);

const persistConfig = {
  key: "delegation-app-v1.0.13",
  storage: storage,
  blacklist: [],
  transforms: [transformCircular],
};

const configureStore = () => {
  let store = createStore(persistReducer(persistConfig, reducers), composeWithDevTools(applyMiddleware(...middleware)));

  let persistor = persistStore(store);

  return { store, persistor };
};

export default configureStore;
