import React from "react";
import { Col, Popover, Row, Spin } from "antd";
import { connect } from "react-redux";
import { AppState } from "../../redux/states/app";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useWeb3React } from "@web3-react/core";
import { EpochRewardsData } from "../../types";

interface Props {
  app: AppState;
  outstandingRewards: EpochRewardsData[];
  currentEpochRewards: number;
  loading: boolean;
}

const RewardsValuesModule = (props: Props) => {
  const { isActive } = useWeb3React();

  const content = (
    <div>
      {props.outstandingRewards.length > 0 ? (
        props.outstandingRewards.map((reward, index) => {
          return (
            <p key={index}>
              <strong>Epoch {reward.epochId}</strong>: {reward.amount.toFixed(5)} {props.app.nat}
            </p>
          );
        })
      ) : (
        <span>
          <em>No pending rewards</em>
        </span>
      )}
    </div>
  );

  const totalRewards = props.outstandingRewards.reduce((a: number, b: { amount: number }) => b.amount + a, 0);
  const fullyLoaded = isActive && !props.loading && props.app.walletStatus === "connect";

  return (
    <Spin spinning={props.loading}>
      <Row>
        <Col xs={24}>
          <div className={"rewards-statistics"}>
            <div id={"total-epoch-rewards"}>
              <strong>Total Outstanding Rewards: </strong>
              <span className={"rewards-value"}>
                {fullyLoaded ? (
                  <span>
                    {totalRewards.toFixed(2)} {props.app.nat}&nbsp;
                  </span>
                ) : (
                  <span>0 {props.app.nat}</span>
                )}
                {fullyLoaded && (
                  <Popover content={content} title="Total Rewards Breakdown" trigger="hover">
                    <InfoCircleOutlined />
                  </Popover>
                )}
              </span>
            </div>
            <div id={"current-epoch-rewards"}>
              <strong>Current Epoch Rewards: </strong>
              <span className={"rewards-value"}>
                {fullyLoaded ? (
                  <span>
                    {props.currentEpochRewards} {props.app.nat}
                  </span>
                ) : (
                  <span>0 {props.app.nat}</span>
                )}
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Spin>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
});

export default connect(mapStateToProps)(RewardsValuesModule);
