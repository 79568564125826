import React, { useEffect, useState } from "react";
import { Button, Col, InputNumber, message, Row, Spin } from "antd";
import {Contract, ethers} from "ethers";
import { AppState } from "../../redux/states/app";
import { contracts } from "../../contracts";
import { connect } from "react-redux";
import { SwapOutlined } from "@ant-design/icons";
import { useWeb3React } from "@web3-react/core";
import Swal from "sweetalert2";
import SVG from "react-inlinesvg";
import { handleTxError } from "../../helpers/tx-error.helper";

interface Props {
  app: AppState;
}

const WrapModule = (props: Props) => {
  const [action, setAction] = useState<"wrap" | "unwrap">("wrap");
  const { isActive, account, provider } = useWeb3React();
  const [loadingTransaction, setLoadingTransaction] = useState(false);
  const [balanceNAT, setBalanceNAT] = useState(0);
  const [loadingNAT, setLoadingNAT] = useState(false);
  const [balanceWNAT, setBalanceWNAT] = useState(0);
  const [loadingWNAT, setLoadingWNAT] = useState(false);
  const [amount, setAmount] = useState<number | null>(null);

  useEffect(() => {
    if (isActive && account) {
      try {
        getBalanceNAT(account);
        getBalanceWNAT(account);
      } catch (e) {
        setBalanceNAT(0);
        setBalanceWNAT(0);
      }
    }
  }, [account, provider]);

  const getBalanceNAT = async (account: string) => {
    setLoadingNAT(true);
    if (provider?.getBalance) {
      try {
        const balance = await provider.getBalance(account);
        const balanceValue = parseInt(balance.toString()) / 1e18;
        setBalanceNAT(balanceValue);
      } catch (e) {
        setBalanceNAT(0);
        setBalanceWNAT(0);
      }
    }
    setLoadingNAT(false);
  };

  const getBalanceWNAT = async (account: string) => {
    setLoadingWNAT(true);
    try {
      let contract = new Contract(
        props.app.wnatAddress[props.app.network],
        contracts[props.app.network].balanceContract,
        provider
      );
      const balance = await contract.balanceOf(account);
      const balanceValue = parseInt(balance.toString()) / 1e18;

      setBalanceWNAT(balanceValue);
    } catch (e) {
      setBalanceNAT(0);
      setBalanceWNAT(0);
    }
    setLoadingWNAT(false);
  };

  const wrap = async () => {
    if (!amount) {
      Swal.fire({
        title: "Invalid Amount",
        icon: "warning",
        html: `Please enter an amount between <strong>0</strong> and <strong>${getFormattedAmount()}</strong>.`,
      });
    } else if (amount === 0) {
      Swal.fire({
        title: `Nothing to ${action}`,
        icon: "warning",
        html: `Your balance is 0`,
      });
    } else if (amount > (action === "wrap" ? balanceNAT : balanceWNAT)) {
      Swal.fire({
        title: "Max Amount Exceeded",
        icon: "warning",
        html: `Please enter an amount between <strong>0</strong> and <strong>${getFormattedAmount()}</strong>.`,
      });
    } else if (account) {
      // @ts-ignore
      const signer = provider.getSigner();
      const wNatContractABI = contracts[props.app.network].wNatContract;
      const wNatContract = new Contract(wNatContractABI.address, wNatContractABI.abi, signer);

      let tx;

      try {
        if (action === "wrap") {
          tx = await wNatContract.deposit({ value: ethers.utils.parseEther(amount.toString()) });
        } else {
          tx = await wNatContract.withdraw(ethers.utils.parseEther(amount.toString()));
        }

        setLoadingTransaction(true);
        const loadingMessage = message.loading("Waiting for transaction to be completed...", 0);
        await tx.wait();
        loadingMessage();

        getBalanceNAT(account);
        getBalanceWNAT(account);
        setLoadingTransaction(false);
        setAmount(null);

        Swal.fire({
          title: "Successful!",
          icon: "success",
          html: `You have successfully wrapped <strong>${amount} ${action === "wrap" ? props.app.nat : props.app.wnat}</strong>.`,
        });
      } catch (e: any) {
        message.destroy();
        handleTxError(e);
      }
    }
  };

  const getFormattedAmount = () => {
    return `${action === "wrap" ? balanceNAT : balanceWNAT} ${action === "wrap" ? props.app.nat : props.app.wnat}`;
  };

  return (
    <Row justify={"center"}>
      <Col xs={24} lg={18} xxl={12}>
        <div className={"widget-container"}>
          <Row gutter={[20, 20]}>
            <Col xs={24}>
              <Row>
                <Col xs={24}>
                  <h2 className={"wrap-title-container"}>
                    <span className={`wrap-title${action === "wrap" ? "-active" : ""}`}>Wrap</span>
                    {" / "}
                    <span className={`wrap-title${action === "unwrap" ? "-active" : ""}`}>Unwrap</span>
                  </h2>
                </Col>
              </Row>
              <Spin spinning={loadingNAT || loadingWNAT}>
                <Row className={"wrap-row"} align={"middle"}>
                  <Col xs={{ span: 24, order: 0 }} md={{ span: 12, order: 0 }}>
                    <h3 id={"from-label"}>From</h3>
                  </Col>
                  <Col xs={{ span: 24, order: 3 }} md={{ span: 12, order: 3 }}>
                    <h3 id={"to-label"}>To</h3>
                  </Col>
                  <Col xs={{ span: 24, order: 1 }} md={{ span: 11, order: 3 }}>
                    <div className={`wrap-asset from ${action}`}>
                      <div className={"wrap-asset-label"}>
                        {props.app.network === "flare" && <SVG src={require("../../assets/flare-logo.svg").default} />}
                        {props.app.network === "songbird" && <SVG src={require("../../assets/songbird-logo.svg").default} />}
                        <strong>{action === "wrap" ? props.app.nat : props.app.wnat}</strong>
                      </div>
                      <hr />
                      <div>
                        <strong>Balance: </strong>
                        <span>
                          {isActive ? (action === "wrap" ? balanceNAT : balanceWNAT) : "-"}{" "}
                          {action === "wrap" ? props.app.nat : props.app.wnat}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={{ span: 24, order: 2 }} md={{ span: 2, order: 3 }}>
                    <div className={"wrap-switch-container"}>
                      <div className={"wrap-switch"} onClick={() => setAction(action === "wrap" ? "unwrap" : "wrap")}>
                        <SwapOutlined />
                      </div>
                    </div>
                  </Col>
                  <Col xs={{ span: 24, order: 4 }} md={{ span: 11, order: 3 }}>
                    <div className={`wrap-asset to ${action}`}>
                      <div className={"wrap-asset-label"}>
                        {props.app.network === "flare" && <SVG src={require("../../assets/flare-logo.svg").default} />}
                        {props.app.network === "songbird" && <SVG src={require("../../assets/songbird-logo.svg").default} />}
                        <strong>{action === "wrap" ? props.app.wnat : props.app.nat}</strong>
                      </div>
                      <hr />
                      <div>
                        <strong>Balance: </strong>
                        <span>
                          {isActive ? (action === "wrap" ? balanceWNAT : balanceNAT) : "-"}{" "}
                          {action === "wrap" ? props.app.wnat : props.app.nat}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Spin>
            </Col>

            <Col xs={24}>
              <span
                id={`max-value-button`}
                className={isActive && !loadingTransaction ? "active" : "disabled"}
                onClick={() =>
                  isActive && !loadingTransaction ? setAmount(action === "wrap" ? balanceNAT : balanceWNAT) : undefined
                }
              >
                Max: {getFormattedAmount()}
              </span>
              <InputNumber
                decimalSeparator={"."}
                disabled={!isActive || loadingTransaction}
                inputMode={"decimal"}
                onChange={(e) => setAmount(e as number)}
                addonAfter={action === "wrap" ? props.app.nat : props.app.wnat}
                placeholder={"Enter Amount..."}
                style={{ width: "100%" }}
                size={"large"}
                value={amount}
                min={0}
                max={action === "wrap" ? balanceNAT : balanceWNAT}
              />
            </Col>

            <Col xs={24}>
              <Row>
                <Col xs={24}>
                  {action === "wrap" ? (
                    <Button
                      onClick={wrap}
                      disabled={!isActive || loadingTransaction}
                      type={isActive ? "primary" : "default"}
                      size={"large"}
                      block={true}
                    >
                      {loadingTransaction ? "Wrapping in progress..." : "Wrap..."}
                    </Button>
                  ) : (
                    <Button
                      onClick={wrap}
                      disabled={!isActive || loadingTransaction}
                      type={isActive ? "primary" : "default"}
                      size={"large"}
                      block={true}
                    >
                      {loadingTransaction ? "Unwrapping in progress" : "Unwrap..."}
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
});

export default connect(mapStateToProps)(WrapModule);
