import { APP_SET_ASSETS, APP_SET_NETWORK, APP_SET_WALLET_STATUS } from "../types";
import { AppState } from "../states/app";

export default function app(state = AppState, action: any) {
  switch (action.type) {
    case APP_SET_NETWORK:
      return {
        ...state,
        network: action.payload,
        chainId: action.payload === "flare" ? 14 : 19,
        nat: action.payload === "flare" ? "FLR" : "SGB",
        wnat: action.payload === "flare" ? "WFLR" : "WSGB",
        rpcUrl: action.payload === "flare" ? process.env.REACT_APP_FLARE_RPC_URL : process.env.REACT_APP_SONGBIRD_RPC_URL,
      };
    case APP_SET_WALLET_STATUS:
      return {
        ...state,
        walletStatus: action.payload,
      };
    case APP_SET_ASSETS:
      return {
        ...state,
        assets: action.payload,
      };
    default:
      return state;
  }
}
