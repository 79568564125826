import React from "react";
import { useWeb3React } from "@web3-react/core";
import { setWalletStatus } from "../../redux/actions/app";
import { connect } from "react-redux";
import { WalletOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import { AppState } from "../../redux/states/app";
import { message } from "antd";

interface Props {
  app: AppState;
  setWalletStatus: typeof setWalletStatus;
  type: "default" | "primary" | "minimal";
  label: string;
}

const WalletConnectButton = (props: Props) => {
  const { isActive, connector } = useWeb3React();

  const connect = async () => {
    // @ts-ignore
    if (window.ethereum) {
      try {
        props.setWalletStatus("connect");
        connector.activate(props.app.chainId);
      } catch (ex) {
        message.warning("Unable to connect to wallet");
      }
    } else {
      Swal.fire({
        title: "No Wallet Provider",
        icon: "warning",
        html: `Please install a browser plugin (like MetaMask) in order to connect to a wallet.`,
      });
    }
  };

  const disconnect = async () => {
    try {
      props.setWalletStatus("disconnect");
      if (connector.deactivate) {
        void connector.deactivate();
      } else {
        void connector.resetState();
      }
    } catch (ex) {
      message.warning("Unable to disconnect from wallet. Please refresh the page and try again.");
    }
  };

  return (
    <div className={`wallet-connect-button ${isActive ? "active" : ""} ${props.type}`}>
      {isActive ? (
        <div onClick={() => disconnect()}>
          <WalletOutlined /> <span className={"wallet-connect-button-label"}>Connected</span>
        </div>
      ) : (
        <div onClick={() => connect()}>
          <WalletOutlined /> <span className={"wallet-connect-button-label"}>{props.label}</span>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch: any) => ({
  setWalletStatus: (status: "connect" | "disconnect") => dispatch(setWalletStatus(status)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletConnectButton);
