import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import { initializeConnector, Web3ReactProvider } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import "antd/dist/antd.less";
import "./styles/index.less";

const Redux = configureStore();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const [metaMask, hooks] = initializeConnector<MetaMask>((actions) => new MetaMask({ actions }));

root.render(
  <PersistGate loading={null} persistor={Redux.persistor}>
    <Provider store={Redux.store}>
      <Web3ReactProvider connectors={[[metaMask, hooks]]}>
        <App />
      </Web3ReactProvider>
    </Provider>
  </PersistGate>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
