import { BigNumber } from "ethers";

export function bigNumberToMillis(num: number) {
  return;
}

export class EpochSettings {
  private _firstEpochStartTime: BigNumber; // in milliseconds
  private _submitPeriod: BigNumber; // in milliseconds
  private _revealPeriod: BigNumber; // in milliseconds
  private _firstEpochId: BigNumber = BigNumber.from(0);

  // all values are in milliseconds
  constructor(_firstEpochStartTime: BigNumber, _submitPeriod: BigNumber, _revealPeriod: BigNumber) {
    this._firstEpochStartTime = BigNumber.from(_firstEpochStartTime.toNumber() * 1000);
    this._submitPeriod = BigNumber.from(_submitPeriod.toNumber() * 1000);
    this._revealPeriod = BigNumber.from(_revealPeriod.toNumber() * 1000);
  }

  private getEpochIdForTime(timeInMillis: number): BigNumber {
    const diff: BigNumber = BigNumber.from(timeInMillis).sub(this._firstEpochStartTime);
    return this._firstEpochId.add(diff.div(this._submitPeriod));
  }

  getEpochSubmitTimeStart = () => {
    const id: BigNumber = this.getCurrentEpochId().add(this._firstEpochId);
    return this._firstEpochStartTime.add(id.mul(this._submitPeriod));
  };

  getCurrentEpochId(): BigNumber {
    return this.getEpochIdForTime(new Date().getTime());
  }

  // in milliseconds
  getEpochSubmitTimeEnd(): BigNumber {
    const id: BigNumber = this.getCurrentEpochId().add(BigNumber.from(1)).add(this._firstEpochId);
    return this._firstEpochStartTime.add(id.mul(this._submitPeriod));
  }

  // in milliseconds
  getEpochReveaTimeEnd(): BigNumber {
    return this.getEpochSubmitTimeEnd().add(this._revealPeriod);
  }

  // in milliseconds
  getRevealPeriod(): BigNumber {
    return this._revealPeriod;
  }

  // in milliseconds
  getSubmitPeriod(): BigNumber {
    return this._submitPeriod;
  }

  getFirstEpochStartTime(): BigNumber {
    return this._firstEpochStartTime;
  }
}
