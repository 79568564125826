import React from "react";
import { Col, Layout, Row } from "antd";
import HeaderComponent from "./layout/header.component";
import FooterComponent from "./layout/footer.component";
import WrapModule from "./modules/wrap.module";
import "../styles/wrap.less";

const { Content } = Layout;

function WrapComponent() {
  return (
    <Layout className="layout page-wrap">
      <HeaderComponent />
      <Content>
        <Row gutter={[20, 20]}>
          <Col xs={24}>
            <WrapModule />
          </Col>
        </Row>
      </Content>
      <FooterComponent />
    </Layout>
  );
}

export default WrapComponent;
