import React, { useState } from "react";
import { Dropdown, Layout, Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { setNetwork } from "../../redux/actions/app";
import { connect } from "react-redux";
import { AppState } from "../../redux/states/app";
import { DownOutlined } from "@ant-design/icons";
import useWindowSize from "../../helpers/window-resize.helper";
import SVG from "react-inlinesvg";
import WalletConnectButton from "../app/wallet-connect-button";
import "../../styles/header.less";

const { Header } = Layout;

interface Props {
  app: AppState;
  setNetwork: typeof setNetwork;
}

function HeaderComponent(props: Props) {
  const [menuVisible, setMenuVisible] = useState(false);
  const location = useLocation();
  const windowSize = useWindowSize();
  const dropdownContainer = document.getElementById("header");

  const navigationMenu = (
    <Menu
      style={{ display: "inline-block", width: "450px" }}
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={[location.pathname]}
      items={[
        { key: "/", label: <Link to={"/"}>Home</Link> },
        { key: "/wrap", label: <Link to={"/wrap"}>Wrap</Link> },
        { key: "/delegate", label: <Link to={"/delegate"}>Delegate</Link> },
        { key: "/rewards", label: <Link to={"/rewards"}>Rewards</Link> },
        { key: "/wallet", label: <Link to={"/wallet"}>Wallet</Link> },

      ]}
    />
  );

  const networkSelectionMenu = (
    <Menu
      onClick={(e) => (e.key !== "change" ? props.setNetwork(e.key as "flare" | "songbird") : undefined)}
      style={{ width: 200 }}
      items={[
        {
          label: (
            <span>
              <img
                alt={"Flare Logo"}
                width={15}
                style={{ marginRight: 10 }}
                src={require("../../assets/flare-logo.svg").default}
              />
              Flare
            </span>
          ),
          key: "flare",
        },
        {
          label: (
            <span>
              <img
                alt={"Songbird Logo"}
                width={15}
                style={{ marginRight: 10 }}
                src={require("../../assets/songbird-logo.svg").default}
              />
              Songbird
            </span>
          ),
          key: "songbird",
        },
        {
          type: "divider",
        },
        {
          key: "change",
          label: (
            <span style={{ color: "#aaa" }}>
              When changing network, make sure to change to the right wallet in MetaMask as well.
            </span>
          ),
        },
      ]}
    />
  );

  return (
    <Header id={"header"}>
      <div id={"header-logo"}>
        <Link to={"/"}>
          <img alt={"App Logo"} src={require("../../assets/app-logo.png")} />
        </Link>
      </div>

      <div id={"header-navigation-container"}>{windowSize.width > 860 && navigationMenu}</div>

      <div id={"header-dropdown-container"}>
        <WalletConnectButton type={"minimal"} label={"Connect"} />

        <div id={"header-dropdown-network-selection"}>
          <Dropdown overlay={networkSelectionMenu} trigger={["click"]}>
            <div id={"header-network-label-container"}>
              <span id={"header-network-logo"}>
                {props.app.network === "flare" && <SVG src={require("../../assets/flare-logo.svg").default} />}
                {props.app.network === "songbird" && <SVG src={require("../../assets/songbird-logo.svg").default} />}
              </span>
              <span id={"header-network-label"}>{props.app.network}</span>
              <DownOutlined />
            </div>
          </Dropdown>
        </div>

        {windowSize.width <= 860 && (
          <Dropdown
            getPopupContainer={() => (dropdownContainer ? dropdownContainer : document.body)}
            overlay={navigationMenu}
            trigger={["click"]}
            onOpenChange={(e) => setMenuVisible(e)}
          >
            <div id={"header-menu-hamburger-container"}>
              <div id="header-menu-hamburger" className={menuVisible ? "open" : ""}>
                <span />
                <span />
                <span />
                <span />
              </div>
            </div>
          </Dropdown>
        )}
      </div>
    </Header>
  );
}

const mapStateToProps = (state: any) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch: any) => ({
  setNetwork: (network: "flare" | "songbird") => dispatch(setNetwork(network)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
