import { APP_SET_ASSETS, APP_SET_NETWORK, APP_SET_WALLET_STATUS } from "../types";

export const setWalletStatus = (status: "connect" | "disconnect") => ({
  type: APP_SET_WALLET_STATUS,
  payload: status,
});

export const setNetwork = (network: "songbird" | "flare") => ({
  type: APP_SET_NETWORK,
  payload: network,
});

export const setAssets = (assets: { [key: string]: number }) => ({
  type: APP_SET_ASSETS,
  payload: assets,
});
