export interface AppState {
  network: "songbird" | "flare";
  chainId: 14 | 19;
  currency: { symbol: string; name: string };
  assets: { [key: string]: number };
  nat: "SGB" | "FLR";
  wnat: "WSGB" | "WFLR";
  wnatAddress: { flare: string; songbird: string };
  rpcUrl: string;
  walletStatus: "connect" | "disconnect";
  explorerUrl: { flare: string; songbird: string };
  tokens: { flare: { name: string; address: string }[]; songbird: { name: string; address: string }[] };
}
export const AppState: AppState = {
  network: "songbird",
  currency: { symbol: "$", name: "USD" },
  nat: "SGB",
  wnat: "WSGB",
  rpcUrl: process.env.REACT_APP_SONGBIRD_RPC_URL as string,
  chainId: 19,
  assets: {},
  explorerUrl: { flare: "https://flare-explorer.flare.network", songbird: "https://songbird-explorer.flare.network" },
  wnatAddress: { flare: "0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d", songbird: "0x02f0826ef6ad107cfc861152b32b52fd11bab9ed" },
  walletStatus: "connect",
  tokens: {
    flare: [{ name: "WFLR", address: "0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d" }],
    songbird: [{ name: "WSGB", address: "0x02f0826ef6ad107cfc861152b32b52fd11bab9ed" }],
  },
};
