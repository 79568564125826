import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { Contract, providers } from "ethers";
import { setAssets } from "../../redux/actions/app";
import { AppState } from "../../redux/states/app";
import { contracts } from "../../contracts";
import CountUp from "react-countup";
import { EpochSettings } from "../../helpers/epoch-settings.helper";
import Countdown from "react-countdown";
import { useWeb3React } from "@web3-react/core";

interface Props {
  app: AppState;
  setAssets: typeof setAssets;
}

const AssetPricesModule = (props: Props) => {
  const { provider } = useWeb3React();
  const [latestPricesUpdate, setLatestPricesUpdate] = useState(moment().utc());
  const [timeLeftTillReveal, setTimeLeftTillReveal] = useState<number | null>(null);
  const ftsoRegistry = contracts[props.app.network].ftsoRegistry;
  const ftsoManager = contracts[props.app.network].ftsoManager;

  const [loading, setLoading] = useState(true);
  const [loadingEpochSettings, setLoadingEpochSettings] = useState(true);
  let ftsoContract: Contract | null = null; // needed to fix bug that listeners are not removed on unmount

  useEffect(() => {
    const rpcProvider = new providers.WebSocketProvider(props.app.rpcUrl);
    const ftsoRegistryContract = new Contract(ftsoRegistry.address, ftsoRegistry.abi, rpcProvider);
    const ftsoManagerContract = new Contract(ftsoManager.address, ftsoManager.abi, rpcProvider);

    removeListeners();
    setLoading(true);
    setLoadingEpochSettings(true);
    setLatestPrices(ftsoRegistryContract);
    getEpochSettings(ftsoManagerContract);
    finalizationListener(ftsoRegistryContract, rpcProvider);

    return () => {
      removeListeners();
    };
  }, [provider]);

  const removeListeners = () => {
    if (ftsoContract) {
      ftsoContract.removeAllListeners();
    }
  };

  const getEpochSettings = async (ftsoManagerContract: any) => {
    const data = await ftsoManagerContract.getPriceEpochConfiguration();
    const epochSettings = new EpochSettings(data[0], data[1], data[2]);
    const epochRevealTimeEnd: number = epochSettings.getEpochReveaTimeEnd().toNumber();

    const now = new Date().getTime();
    const _timeLeftTillReveal = epochRevealTimeEnd - now;
    setTimeLeftTillReveal(epochRevealTimeEnd);
    setLoadingEpochSettings(false);

    setTimeout(() => {
      setLoadingEpochSettings(true);
      getEpochSettings(ftsoManagerContract);
    }, _timeLeftTillReveal + 1500);
  };

  const setLatestPrices = (ftsoRegistryContract: any) => {
    ftsoRegistryContract.getSupportedSymbols().then((supportedSymbols: string[]) => {
      Promise.all(
        supportedSymbols.map((symbol) => {
          return ftsoRegistryContract["getCurrentPrice(string)"](symbol);
        })
      )
        .then((prices) => {
          let newPrices: { [key: string]: number } = {};
          prices.forEach((price, i) => {
            newPrices[supportedSymbols[i]] = Number(price._price) / 10 ** 5;
          });
          setLatestPricesUpdate(moment());
          setLoading(false);
          props.setAssets(newPrices);
        })
        .catch((err) => {
          setLatestPrices({});
          setLoading(false);
        });
    });
  };

  async function finalizationListener(ftsoRegistryContract: any, provider: any) {
    let supportedFtsos = await ftsoRegistryContract.getSupportedFtsos();
    const priceFinalizedAbi = contracts[props.app.network].priceFinalizedAbi;
    let ftso = { address: supportedFtsos[0], abi: priceFinalizedAbi };
    if (!ftsoContract) {
      ftsoContract = new Contract(ftso.address, ftso.abi, provider);
      ftsoContract.on("PriceFinalized", async (e) => {
        setLatestPrices(ftsoRegistryContract);
      });
    }
  }

  return (
    <Spin spinning={loading}>
      <Row gutter={[10, 10]}>
        {Object.keys(props.app.assets).map((assetKey, index) => {
          return (
            <Col key={index} xs={24} md={12} lg={8} xl={6}>
              <div className={"asset-price-container"}>
                <img alt={`${assetKey} Logo`} src={require(`../../assets/${assetKey.toLowerCase()}_64x64.png`)} />
                <span>{assetKey}</span>
                <span className={"asset-price-value"}>
                  <CountUp
                    decimal={","}
                    prefix={props.app.currency.symbol}
                    separator={"."}
                    decimals={5}
                    preserveValue={true}
                    start={0}
                    end={props.app.assets[assetKey]}
                    delay={0}
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                </span>
              </div>
            </Col>
          );
        })}
      </Row>
      <div className={"widget-container-footer"}>
        <span>
          <em>
            Time till next price reveal:{" "}
            {timeLeftTillReveal && !loadingEpochSettings ? (
              <Countdown daysInHours={true} date={timeLeftTillReveal + 1500} />
            ) : (
              "-"
            )}
          </em>
        </span>
        <div id={"latest-update-label"}>
          <em>Latest Update: {latestPricesUpdate.format("DD MMM HH:mm:ss")}</em>
        </div>
      </div>
    </Spin>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
});

const mapDispatchToProps = (dispatch: any) => ({
  setAssets: (assets: { [key: string]: number }) => dispatch(setAssets(assets)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssetPricesModule);
