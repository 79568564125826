import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import { connect } from "react-redux";
import { Contract, providers } from "ethers";
import { AppState } from "../../redux/states/app";
import moment from "moment";
import Countdown from "react-countdown";
import { contracts } from "../../contracts";
import { useWeb3React } from "@web3-react/core";

interface Props {
  app: AppState;
  showPriceEpoch: boolean;
}

const EpochStatsModule = (props: Props) => {
  const { provider } = useWeb3React();
  const [loadingPriceEpoch, setLoadingPriceEpoch] = useState(true);
  const [loadingRewardEpoch, setLoadingRewardEpoch] = useState(true);
  const [priceEpochData, setPriceEpochData] = useState<{
    currentEpoch: number;
    start: number;
    end: number;
  }>({ currentEpoch: 0, start: 0, end: 0 });
  const [rewardEpochData, setRewardEpochData] = useState<{
    currentEpoch: number;
    start: number;
    end: number;
  }>({ currentEpoch: 0, start: 0, end: 0 });

  useEffect(() => {
    const rpcProvider = new providers.WebSocketProvider(props.app.rpcUrl);
    const ftsoManager = contracts[props.app.network].ftsoManager;
    const ftsoManagerContract = new Contract(ftsoManager.address, ftsoManager.abi, rpcProvider);

    setLoadingRewardEpoch(true);
    setLoadingPriceEpoch(true);
    getRewardEpochData(ftsoManagerContract);
    getPriceEpochData(ftsoManagerContract);
  }, [provider]);

  const getRewardEpochData = async (ftsoManagerContract: any) => {
    const currentRewardEpoch = await ftsoManagerContract.getCurrentRewardEpoch();
    const rewardEpochDuration = await ftsoManagerContract.rewardEpochDurationSeconds();
    const firstRewardEpochTs = await ftsoManagerContract.rewardEpochsStartTs();
    const totalEpochTimeElapsed = currentRewardEpoch.toNumber() * rewardEpochDuration.toNumber();
    const currentRewardEpochStart = firstRewardEpochTs.toNumber() + totalEpochTimeElapsed;
    const currentRewardEpochEnd = currentRewardEpochStart + rewardEpochDuration.toNumber();

    setRewardEpochData({
      currentEpoch: currentRewardEpoch.toNumber(),
      start: currentRewardEpochStart,
      end: currentRewardEpochEnd,
    });
    setLoadingRewardEpoch(false);

    // Reload epoch data
    const currentTime = moment().unix();
    const diffTime = currentRewardEpochEnd - currentTime;
    setTimeout(() => {
      setLoadingRewardEpoch(true);
      setTimeout(() => {
        getRewardEpochData(ftsoManagerContract);
      }, 500);
    }, diffTime * 1000);
  };

  const getPriceEpochData = async (ftsoManagerContract: any) => {
    const _priceEpochData = await ftsoManagerContract.getCurrentPriceEpochData();

    let currentEpoch;
    let priceEpochStarted;
    let priceEpochEnds;

    if (props.app.network === "flare") {
      priceEpochStarted = _priceEpochData._priceEpochStartTimestamp.toNumber();
      priceEpochEnds = _priceEpochData._priceEpochEndTimestamp.toNumber();
      currentEpoch = _priceEpochData._priceEpochId.toNumber();
    } else {
      priceEpochStarted = _priceEpochData.priceEpochStartTimestamp.toNumber();
      priceEpochEnds = _priceEpochData.priceEpochEndTimestamp.toNumber();
      currentEpoch = _priceEpochData.priceEpochId.toNumber();
    }

    setPriceEpochData({
      currentEpoch: currentEpoch,
      start: priceEpochStarted,
      end: priceEpochEnds,
    });

    setLoadingPriceEpoch(false);

    const currentTime = moment().unix();
    const diffTime = priceEpochEnds - currentTime;
    setTimeout(() => {
      setLoadingPriceEpoch(true);
      setTimeout(() => {
        getPriceEpochData(ftsoManagerContract);
      }, 500);
    }, diffTime * 1000);
  };

  const isLoading = loadingPriceEpoch || loadingRewardEpoch;

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[20, 20]}>
        <Col xs={24} md={props.showPriceEpoch ? 12 : 24}>
          <div>
            <strong>Reward Epoch: </strong>
            <span className={"epoch-value"}>{loadingRewardEpoch ? "-" : rewardEpochData.currentEpoch}</span>
          </div>
          <div>
            <strong>Started: </strong>
            <span className={"epoch-value"}>
              {loadingRewardEpoch ? "-" : moment.unix(rewardEpochData.start).format("DD MMMM HH:mm")}
            </span>
          </div>
          <div>
            <strong>Ends: </strong>
            <span className={"epoch-value"}>
              {loadingRewardEpoch ? "-" : moment.unix(rewardEpochData.end).format("DD MMMM HH:mm")}
            </span>
          </div>
          <div>
            <strong>Time Left: </strong>
            <span className={"epoch-value"}>{isLoading ? "-" : <Countdown date={rewardEpochData.end * 1000} />}</span>
          </div>
        </Col>
        {props.showPriceEpoch && (
          <Col xs={24} md={12}>
            <div>
              <strong>Price Epoch: </strong>
              <span className={"epoch-value"}>{loadingPriceEpoch ? "-" : priceEpochData.currentEpoch}</span>
            </div>
            <div>
              <strong>Started: </strong>
              <span className={"epoch-value"}>
                {loadingPriceEpoch ? "-" : moment.unix(priceEpochData.start).format("DD MMM HH:mm")}
              </span>
            </div>
            <div>
              <strong>Ends: </strong>
              <span className={"epoch-value"}>
                {loadingPriceEpoch ? "-" : moment.unix(priceEpochData.end).format("DD MMM HH:mm")}
              </span>
            </div>
            <div>
              <strong>Time Left: </strong>
              <span className={"epoch-value"}>
                {isLoading ? "-" : <Countdown daysInHours={true} date={priceEpochData.end * 1000} />}
              </span>
            </div>
          </Col>
        )}
      </Row>
    </Spin>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
});

export default connect(mapStateToProps)(EpochStatsModule);
