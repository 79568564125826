import { connect } from "react-redux";
import { AppState } from "../../redux/states/app";
import { Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { Contract } from "ethers";
import { contracts } from "../../contracts";
import WalletConnectButton from "../app/wallet-connect-button";

interface Props {
  app: AppState;
}

const BalanceModule = (props: Props) => {
  const { isActive, account, provider } = useWeb3React();
  const [balanceWNAT, setBalanceWNAT] = useState(0);
  const [otherBalances, setOtherBalances] = useState<{ name: string; value: number }[]>([]);
  const [loadingWNAT, setLoadingWNAT] = useState(false);
  const [loadingOtherTokens, setLoadingOtherTokens] = useState(false);

  const getBalanceWNAT = async (account: string) => {
    setLoadingWNAT(true);
    if (provider?.getBalance) {
      const balance = await provider?.getBalance(account);
      const balanceValue = parseInt(balance.toString()) / 1e18;
      setBalanceWNAT(balanceValue);
    }
    setLoadingWNAT(false);
  };

  const getBalancesOfOtherTokens = async (account: string) => {
    setLoadingOtherTokens(true);
    const balances: { name: string; value: number }[] = [];
    for (const token of props.app.tokens[props.app.network]) {
      let contract = new Contract(token.address, contracts[props.app.network].balanceContract, provider);
      const balance = await contract.balanceOf(account);
      const balanceValue = parseInt(balance.toString()) / 1e18;
      balances.push({ name: token.name, value: balanceValue });
    }
    setOtherBalances(balances);
    setLoadingOtherTokens(false);
  };

  useEffect(() => {
    if (account) {
      getBalanceWNAT(account);
      getBalancesOfOtherTokens(account);
    } else {
      setBalanceWNAT(0);
      setOtherBalances([]);
    }
  }, [account, provider]);

  return (
    <Row>
      <Col xs={24}>
        {isActive ? (
          <Spin spinning={loadingWNAT || loadingOtherTokens}>
            <Row>
              <Col xs={24}>
                <div>
                  <div className={"balance-wnat"}>
                    {balanceWNAT.toFixed(2)} {props.app.nat}
                  </div>
                </div>
                {otherBalances.map((otherBalance, index) => {
                  return (
                    <div key={index} className={"balance-other"}>
                      <strong>{otherBalance.name}:</strong>&nbsp;
                      <span>{otherBalance.value}</span>
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Spin>
        ) : (
          <div className={"connect-wallet-container"}>
            <WalletConnectButton label={"Connect"} type={"default"} />
          </div>
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
});

export default connect(mapStateToProps)(BalanceModule);
