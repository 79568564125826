import { message } from "antd";

export const handleTxError = (e: any) => {
  if (e.code === "ACTION_REJECTED") {
    message.info("The transaction has been cancelled");
  } else {
    message.destroy();
    message.info("Unable to send transaction");
  }
};
