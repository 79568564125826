import React from "react";
import {createBrowserRouter} from "react-router-dom";
import HomeComponent from "./components/home.component";
import WrapComponent from "./components/wrap.component";
import DelegateComponent from "./components/delegate.component";
import RewardsComponent from "./components/rewards.component";
import NotFoundComponent from "./components/app/not-found";
import WalletComponent from "./components/wallet.component";
import MintComponent from "./components/mint.component";


const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <HomeComponent/>,
        },
        {
            path: "/wrap",
            element: <WrapComponent/>,
        },
        {
            path: "/delegate",
            element: <DelegateComponent/>,
        },
        {
            path: "/rewards",
            element: <RewardsComponent/>,
        },
        {
            path: "/wallet",
            element: <WalletComponent/>,
        },
        {
            path: "/mint",
            element: <MintComponent/>,
        },
        {
            path: "*",
            element: <NotFoundComponent/>,
        },
    ],
    {basename: "/"}
);

export default router;
