import React, { useEffect, useState } from "react";
import { Alert, Button, Col, message, Row, Space } from "antd";
import { connect } from "react-redux";
import EpochStatsComponent from "./epoch-stats.module";
import { AppState } from "../../redux/states/app";
import { useWeb3React } from "@web3-react/core";
import RewardsValuesModule from "./rewards-values.module";
import WalletConnectButton from "../app/wallet-connect-button";
import { Contract } from "ethers";
import { contracts } from "../../contracts";
import { handleTxError } from "../../helpers/tx-error.helper";
import { getRewardsData } from "../../helpers/rewards.helper";
import Swal from "sweetalert2";
import { LoadingOutlined } from "@ant-design/icons";
import { EpochRewardsData } from "../../types";

interface Props {
  app: AppState;
}

const RewardsModule = (props: Props) => {
  const { isActive, provider, account } = useWeb3React();
  const [isClaimingRewards, setIsClaimingRewards] = useState(false);
  const [outstandingRewards, setOutStandingRewards] = useState<EpochRewardsData[]>([]);
  const [currentEpochRewards, setCurrentEpochRewards] = useState(0);
  const [loadingRewardsData, setLoadingRewardsData] = useState(false);

  useEffect(() => {
    if (isActive && account) {
      getData(account);
    } else {
      setOutStandingRewards([]);
    }
  }, [provider, isActive, account]);

  const getData = async (account: string) => {
    setLoadingRewardsData(true);
    const rewardsData = await getRewardsData(provider, props.app.network, account);
    setOutStandingRewards(rewardsData.pendingRewards);
    setCurrentEpochRewards(rewardsData.currentRewards);
    setLoadingRewardsData(false);
  };

  const outStandingRewardsAmount = outstandingRewards.reduce((a, b) => a + b.amount, 0);

  const claimRewards = async () => {
    if (outStandingRewardsAmount === 0) {
      Swal.fire({
        title: "No outstanding rewards",
        icon: "success",
        html: `There are currently no rewards to claim`,
      });
    } else if (provider?.getSigner && account) {
      try {
        const signer = provider.getSigner();

        const ftsoManager = contracts[props.app.network].ftsoManager;
        const ftsoRewardManager = contracts[props.app.network].ftsoRewardManager;
        const ftsoManagerContract = new Contract(ftsoManager.address, ftsoManager.abi, provider);
        const rewardManagerAddress = await ftsoManagerContract.rewardManager();
        const ftsoRewardManagerContract = new Contract(rewardManagerAddress, ftsoRewardManager.abi, signer);

        const loadingApproval = message.loading("Waiting for your approval...", 0);
        const tx = await ftsoRewardManagerContract.claimReward(
          account,
          outstandingRewards.map((data) => data.epochId)
        );
        setIsClaimingRewards(true);
        loadingApproval();
        const loadingConfirmation = message.loading("Waiting for network confirmation...", 0);
        await tx.wait();

        loadingConfirmation();
        getData(account);
        setIsClaimingRewards(false);

        Swal.fire({
          title: "Successful!",
          icon: "success",
          html: `Successfully claimed rewards!`,
        });
      } catch (e) {
        message.destroy();
        handleTxError(e);
      }
    }
  };

  return (
    <Row justify={"center"}>
      <Col xs={24} sm={18} xl={12} xxl={8}>
        <div className={"widget-container"}>
          <Row gutter={[20, 20]}>
            <Col xs={24}>
              <Row>
                <Col xs={24}>
                  <h2 className={"wrap-title"}>Rewards</h2>
                </Col>
              </Row>

              <Row>
                <Alert
                  message="How Does It Work?"
                  description={
                    <ol>
                      <li>A reward epoch runs from Saturday 8:00 AM till the next Saturday 8:00 AM</li>
                      <li>
                        Rewards can be claimed any moment after Saturday 8:00 AM, but no later than 3 months after the next
                        Saturday 8:00 AM after which your rewards will expire.
                      </li>
                      <li>
                        FTSO Data providers are providing various price information to the {props.app.network} network. The more
                        accurate they do this, the higher their success-rate
                      </li>
                      <li>
                        Your rewards are based on the success-rate of the FTSO Data providers you have chosen in the delegation
                        slots.
                      </li>
                      <li>
                        You can see the current success-rate of your FTSO Data providers{" "}
                        <a rel={"noreferrer"} target={"_blank"} href={"https://flaremetrics.io"}>
                          here
                        </a>
                        .
                      </li>
                    </ol>
                  }
                  type="info"
                  showIcon
                />
              </Row>

              <Row>
                <Col xs={24}>
                  <div className={"epoch-statistics"}>
                    <EpochStatsComponent showPriceEpoch={false} />
                  </div>
                </Col>
              </Row>

              <RewardsValuesModule
                loading={loadingRewardsData}
                outstandingRewards={outstandingRewards}
                currentEpochRewards={currentEpochRewards}
              />
            </Col>
            <Col xs={24}>
              <Space direction={"vertical"} style={{ width: "100%" }}>
                <Button
                  icon={isClaimingRewards ? <LoadingOutlined /> : undefined}
                  onClick={() => claimRewards()}
                  disabled={!isActive || isClaimingRewards}
                  size={"large"}
                  block={true}
                  type={"primary"}
                >
                  {isClaimingRewards ? "Claiming Rewards..." : "Claim Rewards..."}
                </Button>

                {!isActive && <WalletConnectButton type={"primary"} label={"Connect Wallet"} />}
              </Space>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: any) => ({
  app: state.app,
});

export default connect(mapStateToProps)(RewardsModule);
