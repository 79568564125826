import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Input, List, message, Modal, Radio, Row, Slider, Space, Spin } from "antd";
import { Contract } from "ethers";
import { AppState } from "../../redux/states/app";
import { contracts } from "../../contracts";
import { connect } from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { ArrowLeftOutlined, LoadingOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { DelegationAddress, FTSOProvider } from "../../types";
import { setWalletStatus } from "../../redux/actions/app";
import Swal from "sweetalert2";
import WalletConnectButton from "../app/wallet-connect-button";
import { handleTxError } from "../../helpers/tx-error.helper";

interface Props {
  app: AppState;
  setWalletStatus: typeof setWalletStatus;
}

const DelegateModule = (props: Props) => {
  const [transactionState, setTransactionState] = useState<"ready" | "pending-acceptance" | "pending-confirmation" | "success">(
    "ready"
  );
  const [openManageProviderModal, setOpenManageProviderModal] = useState(false);
  const [selectedProvider, setselectedProvider] = useState<DelegationAddress | null>(null);
  const [selectedProviderValue, setSelectedProviderValue] = useState(0);
  const [selectedProviderProgress, setSelectedProviderProgress] = useState<"choose-provider" | "choose-percentage" | "done">(
    "choose-provider"
  );
  const [openProviderSelectionModal, setOpenProviderSelectionModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [ftsoProviders, setFTSOProviders] = useState<FTSOProvider[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [delegationMode, setDelegationMode] = useState(null);
  const [delegationAddresses, setDelegationAddresses] = useState<DelegationAddress[]>([]);
  const { isActive, account, provider } = useWeb3React();
  const [balanceWNAT, setBalanceWNAT] = useState(0);
  const [loadingWNAT, setLoadingWNAT] = useState(false);

  useEffect(() => {
    const vpContract = contracts[props.app.network].vpContract;
    const votePowerManager = new Contract(vpContract.address, vpContract.abi, provider);

    if (account) {
      getBalanceOfWNAT(account);
    } else {
      setBalanceWNAT(0);
    }

    setLoading(true);
    fetch("https://raw.githubusercontent.com/TowoLabs/ftso-signal-providers/master/bifrost-wallet.providerlist.json")
      .then((res) => {
        return res.json();
      })
      .then((data) => {

        setFTSOProviders(data.providers);




        if (data.providers.filter((e: any) => e.name == 'FTSchizO').length == 0) {
          data.providers.push(
              {
                chainId: 14,
                name: "FTSchizO",
                description: "FTSchizO is where based esoteric redpilled schizos gather and delegate wSGB while watching based schizo webms",
                url: "https://ftschizo.xyz/",
                address: "0xa41d19F4258a388c639B7CcD938FCE3fb7D05e86",
                logoURI: "https://i.imgur.com/LXlXbkv.png"
              }
          );

          data.providers.push(
              {
                chainId: 19,
                name: "FTSchizO",
                description: "FTSchizO is where based esoteric redpilled schizos gather and delegate wSGB while watching based schizo webms",
                url: "https://ftschizo.xyz/",
                address: "0xa41d19F4258a388c639B7CcD938FCE3fb7D05e86",
                logoURI: "https://i.imgur.com/LXlXbkv.png"
              }
          );
        }
        
        if (data.providers.filter((e: any) => e.name == 'MyFtso').length == 0) {
          data.providers.push(
              {
                chainId: 19,
                name: "MyFtso",
                description: "MyFtso is a community centric data provider. We are looking to deliver accurate and reliable price feeds to the Flare/Songbird Networks to maximize returns for all.",
                url: "https://myftso.xyz",
                address: "0x399a2de69e38d93bc397eb2b1f5487bd25a71c00",
                logoURI: "https://i.imgur.com/XckueJm.jpeg"
              }
          );
        }

        //CFN
        data.providers.push({
          chainId: 19,
          name: "CFN",
          description: "A passive income community driven project based on utility NFTs.",
          url: "https://discord.gg/cfnk",
          address: "0x889FD8C79FCC81E619b720BD589154C2c9fD74e9",
          logoURI: "https://cryptapero.fr/wp-content/uploads/2022/10/CFN.png"
        });

        getVotePowerData(votePowerManager, data.providers);
      })
      .catch((err) => {
        getVotePowerData(votePowerManager, []);
      });
  }, [account, provider]);

  useEffect(() => {
    if (!isActive) {
      setDelegationAddresses([]);
      setBalanceWNAT(0);
    }
  }, [isActive]);

  const getBalanceOfWNAT = async (account: string) => {
    setLoadingWNAT(true);
    try {
      let contract = new Contract(
        props.app.wnatAddress[props.app.network],
        contracts[props.app.network].balanceContract,
        provider
      );
      const balance = await contract.balanceOf(account);
      const balanceValue = parseInt(balance.toString()) / 1e18;
      setBalanceWNAT(balanceValue);
    } catch (e) {
      setBalanceWNAT(0);
    }

    setLoadingWNAT(false);
  };

  const getVotePowerData = async (votePowerManager: any, dataProviders: FTSOProvider[]) => {
    if (account) {
      try {
        const delegates = await votePowerManager.delegatesOf(account);
        const delegationAddresses = delegates._delegateAddresses;
        const _delegationMode = await votePowerManager.delegationModeOf(account);
        const delegationModeValue = _delegationMode.toNumber();
        setDelegationMode(delegationModeValue);

        const unsupportedDelegationModeSGB = props.app.network === "songbird" && delegationModeValue !== 1;
        const unsupportedDelegationModeFLR = props.app.network === "flare" && delegationModeValue !== 0;

        if (unsupportedDelegationModeSGB || unsupportedDelegationModeFLR) {
          message.warning("Detected unsupported delegation mode. This app only supports percentage-wise delegation.");
        }

        const myAddresses = [];
        for (const [index, delegationAddress] of delegationAddresses.entries()) {
          const ftsoProviderIndex = dataProviders.findIndex((provider) => provider.address === delegationAddress);

          const amount = delegates._bips[index].toNumber();

          if (ftsoProviderIndex > -1) {
            myAddresses.push({
              name: dataProviders[ftsoProviderIndex].name,
              logo: dataProviders[ftsoProviderIndex].logoURI,
              address: dataProviders[ftsoProviderIndex].address,
              type: "-",
              value: amount / 100,
            });
          } else {
            myAddresses.push({
              name: "Unknown Provider",
              address: delegationAddress,
              logo: require("../../assets/signal-provider.png"),
              type: "",
              value: amount / 100,
            });
          }
        }
        setDelegationAddresses(myAddresses);
      } catch (e) {
        setBalanceWNAT(0);
        setDelegationAddresses([]);
      }
    }
    setLoading(false);
  };

  const confirmUndelegationAll = () => {
    Swal.fire({
      title: "Are you sure?",
      icon: "info",
      confirmButtonText: "Undelegate",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const vpContract = contracts[props.app.network].wNatContract;
        const signer = provider?.getSigner();
        const votePowerManager = new Contract(vpContract.address, vpContract.abi, signer);

        try {
          const loadingApproval = message.loading("Waiting for approval...", 0);
          const tx = await votePowerManager.undelegateAll();
          loadingApproval();
          const loadingConfirmation = message.loading("Waiting for network confirmation...", 0);
          await tx.wait();

          getVotePowerData(votePowerManager, ftsoProviders);
          loadingConfirmation();

          Swal.fire({
            title: "Successful!",
            icon: "success",
            html: `Successfully undelegated from all providers`,
          });
        } catch (e: any) {
          message.destroy();
          handleTxError(e);
        }
      }
    });
  };

  const delegateToSelectedProvider = async (undelegate = false) => {
    const vpContract = contracts[props.app.network].wNatContract;
    const signer = provider?.getSigner();
    const votePowerManager = new Contract(vpContract.address, vpContract.abi, signer);

    const userConfirmationMessage = message.loading("Waiting for your approval...", 0);

    const delegationValue = undelegate ? 0 : selectedProviderValue * 100;

    try {
      const tx = await votePowerManager.delegate(selectedProvider?.address, delegationValue);
      userConfirmationMessage();
      setOpenManageProviderModal(false);
      setOpenProviderSelectionModal(false);
      const networkConfirmationMessage = message.loading("Waiting for network confirmation...", 0);
      await tx.wait();
      networkConfirmationMessage();

      getVotePowerData(votePowerManager, ftsoProviders);

      let successMessage = `Succesfully delegated <strong>${selectedProviderValue}%</strong> to <strong>${selectedProvider?.name}</strong>`;
      if (undelegate) {
        successMessage = `Succesfully undelegated from <strong>${selectedProvider?.name}</strong>`;
      }

      Swal.fire({
        title: "Succesful!",
        icon: "success",
        html: successMessage,
      });

      setselectedProvider(null);
    } catch (e: any) {
      handleTxError(e);
    }
  };

  const editProvider = (dataProvider: DelegationAddress) => {
    setOpenManageProviderModal(true);
    setTransactionState("ready");
    setselectedProvider(dataProvider);
    setSelectedProviderValue(dataProvider.value);
  };

  const getDescription = () => {
    switch (transactionState) {
      case "pending-acceptance":
        return (
          <span>
            <LoadingOutlined /> Awaiting transaction acceptance...
          </span>
        );
      case "pending-confirmation":
        return (
          <span>
            <LoadingOutlined /> Waiting for transaction to be confirmed...
          </span>
        );
      default:
        return transactionState;
    }
  };

  const closeProviderSelectionModal = () => {
    setOpenProviderSelectionModal(false);
    setSearchQuery("");
    setselectedProvider(null);
    setSelectedProviderProgress("choose-provider");
  };

  const degradeProviderSelection = () => {
    if (selectedProviderProgress === "choose-provider") {
      closeProviderSelectionModal();
    } else if (selectedProviderProgress === "choose-percentage") {
      setSelectedProviderProgress("choose-provider");
    }
  };

  const totalPercentageDelegated = delegationAddresses.reduce((a, b) => a + b.value, 0);
  const delegationRemaining =
    delegationAddresses.length > 1 ? totalPercentageDelegated - (selectedProvider ? selectedProvider?.value : 0) : 100;

  const updateProviderSelectionProgress = () => {
    if (selectedProviderProgress === "choose-provider") {
      setSelectedProviderProgress("choose-percentage");
      setSelectedProviderValue(Math.round((100 - totalPercentageDelegated) / 2));
    } else if (selectedProviderProgress === "choose-percentage") {
      delegateToSelectedProvider();
    }
  };

  return (
    <Row justify={"center"}>
      <Col xs={24} sm={18} xl={12} xxl={8}>
        <div className={"widget-container"}>
          <Row gutter={[20, 20]}>
            <Col xs={24}>
              <Spin spinning={loading || loadingWNAT}>
                <Row>
                  <Col xs={24}>
                    <h2 className={"wrap-title"}>Delegate</h2>
                  </Col>
                </Row>
                <Row>
                  <Alert
                    message="How Does It Work?"
                    description={
                      <ol>
                        <li>Make sure the delegation slots are filled before each Thursday 8:00 AM</li>
                        <li>
                          At a random time between Thursday 8:00 AM and Saturday 8:00 AM a snapshot will be taken to lock in your
                          delegation vote
                        </li>
                        <li>
                          Rewards can be claimed any moment after Thursday 8:00 AM, but no later than 3 months after Saturday 8:00
                          AM
                        </li>
                        <li>
                          Rewards are based on the success-rate of the FTSO Data providers you have chosen in the delegation slots
                        </li>
                      </ol>
                    }
                    type="info"
                    showIcon
                  />
                </Row>
                <Row>
                  <div className={"delegation-statistics"}>
                    <div>
                      <h3>{props.app.wnat} Delegation Overview</h3>
                    </div>
                    <hr />
                    <div>
                      <strong>Allocated:</strong>
                      <span className={"delegation-value"}>
                        {delegationAddresses.reduce((total, address) => address.value + total, 0).toFixed(2)}%
                      </span>
                    </div>
                    <div>
                      <strong>Total {props.app.nat} Available:</strong>
                      <span className={"delegation-value"}>
                        {balanceWNAT} {props.app.wnat}
                      </span>
                    </div>
                    <div>
                      <strong>Total {props.app.nat} Delegated:</strong>
                      <span className={"delegation-value"}>
                        {(balanceWNAT / 100) * totalPercentageDelegated} {props.app.wnat}
                      </span>
                    </div>
                    <div>
                      <strong>Approximate Rewards:</strong>
                      <span className={"delegation-value"}>
                        {(balanceWNAT / 100) * totalPercentageDelegated * 0.02} {props.app.wnat}
                      </span>
                    </div>
                  </div>
                  {delegationAddresses.map((delegationAddress, index) => {
                    return (
                      <div key={index} className={"delegation-slot filled"} onClick={() => editProvider(delegationAddress)}>
                        <img alt={`${delegationAddresses[index].name} Logo`} src={delegationAddresses[index].logo} />

                        <div className={"delegation-details"}>
                          <div className={"delegation-details-name"}>{delegationAddresses[index].name}</div>
                          <div className={"delegation-details-address"}>
                            {`${delegationAddresses[index].address.substring(0, 8)}...${delegationAddresses[
                              index
                            ].address.substring(delegationAddresses[index].address.length - 8)}`}
                          </div>
                        </div>

                        <div className={"delegation-value"}>{delegationAddresses[index].value}%</div>
                      </div>
                    );
                  })}

                  {delegationAddresses.length === 0 && (
                    <div
                      onClick={() => setOpenProviderSelectionModal(true)}
                      className={`delegation-slot ${isActive ? "active" : ""}`}
                    >
                      <PlusCircleOutlined />
                      Delegation Slot 1x
                    </div>
                  )}

                  {delegationAddresses.length <= 1 && (
                    <div
                      onClick={() => setOpenProviderSelectionModal(true)}
                      className={`delegation-slot ${isActive ? "active" : ""}`}
                    >
                      <PlusCircleOutlined />
                      Delegation Slot 2
                    </div>
                  )}
                </Row>
              </Spin>
            </Col>
            <Col xs={24}>
              <Row>
                <Col xs={24}>
                  <Space style={{ width: "100%" }} direction={"vertical"}>
                    <Button
                      onClick={() => confirmUndelegationAll()}
                      disabled={!isActive || delegationAddresses.length === 0}
                      block={true}
                      size={"large"}
                      type={"primary"}
                    >
                      Undelegate all...
                    </Button>
                    {!isActive && <WalletConnectButton label={"Connect Wallet"} type={"primary"} />}
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <Modal
          onCancel={() => closeProviderSelectionModal()}
          footer={[
            <Button
              icon={selectedProviderProgress === "choose-percentage" ? <ArrowLeftOutlined /> : undefined}
              onClick={() => degradeProviderSelection()}
              key={0}
              style={{ float: "left" }}
              type={"default"}
            >
              {selectedProviderProgress === "choose-provider" ? "Cancel" : "Choose Different Provider"}
            </Button>,

            <Button
              onClick={() => updateProviderSelectionProgress()}
              disabled={
                !selectedProvider || (selectedProvider.address === account && selectedProviderProgress === "choose-percentage")
              }
              key={1}
              type={"primary"}
            >
              {selectedProviderProgress === "choose-provider" ? "Continue..." : "Save"}
            </Button>,
          ]}
          title={selectedProviderProgress === "choose-provider" ? "Select Provider" : "Choose Delegation Percentage"}
          open={openProviderSelectionModal}
        >
          {selectedProviderProgress === "choose-provider" ? (
            <div>
              <Input
                allowClear={true}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.currentTarget.value)}
                size={"large"}
                placeholder={"Search provider..."}
              />

              <hr />

              <Radio.Group className={"provider-selection-list-container"} value={selectedProvider?.address}>
                <List
                  className={"provider-selection-list"}
                  itemLayout="horizontal"
                  dataSource={ftsoProviders
                    .filter(
                      (provider) =>
                        provider.chainId === props.app.chainId && provider.name.toLowerCase().includes(searchQuery.toLowerCase())
                    )
                    .sort((a, b) => (a.name == "TheGrungies" ? -2 : a.name == "CFN" && b.name != "TheGrungies" || a.name > b.name ? -1 : 1))}
                  renderItem={(item) => (
                    <List.Item
                      onClick={() =>
                        setselectedProvider({ value: 0, address: item.address, type: "-", logo: item.logoURI, name: item.name })
                      }
                    >
                      <Radio value={item.address} />
                      <List.Item.Meta
                        avatar={<img width={50} alt={`${item.name} Logo`} src={item.logoURI} />}
                        title={item.name}
                        description={item.address}
                      />
                    </List.Item>
                  )}
                />
              </Radio.Group>
            </div>
          ) : (
            selectedProvider && (
              <div>
                <div className={"delegation-slot filled active-provider"}>
                  <img alt={`${selectedProvider.name} Logo`} src={selectedProvider.logo} />

                  <div className={"delegation-details"}>
                    <div className={"delegation-details-name"}>{selectedProvider.name}</div>
                    <div className={"delegation-details-address"}>
                      {`${selectedProvider.address.substring(0, 8)}...${selectedProvider.address.substring(
                        selectedProvider.address.length - 8
                      )}`}
                    </div>
                  </div>

                  {selectedProvider.address !== account && <div className={"delegation-value"}>{selectedProviderValue}%</div>}
                </div>

                {selectedProvider.address === account ? (
                  <em>You can not delegate to yourself. Your {props.app.wnat} tokens will automatically count as delegation.</em>
                ) : (
                  <Slider
                    tooltip={{ open: false }}
                    onChange={(e) => setSelectedProviderValue(e)}
                    marks={{ 0: "0%", [100 - totalPercentageDelegated]: 100 - totalPercentageDelegated + "%" }}
                    min={0}
                    max={100 - totalPercentageDelegated}
                    defaultValue={Math.round((100 - totalPercentageDelegated) / 2)}
                  />
                )}
              </div>
            )
          )}
        </Modal>

        <Modal
          maskClosable={false}
          footer={
            transactionState === "ready" && [
              <Button
                onClick={() => delegateToSelectedProvider(true)}
                key={0}
                danger={true}
                style={{ float: "left" }}
                type={"default"}
              >
                Delete
              </Button>,
              <Button key={1} type={"primary"} onClick={() => delegateToSelectedProvider()}>
                Save
              </Button>,
            ]
          }
          onCancel={() => setOpenManageProviderModal(false)}
          title={"Manage Provider"}
          open={openManageProviderModal}
        >
          {transactionState !== "ready" ? (
            <span>{getDescription()}</span>
          ) : (
            selectedProvider && (
              <div>
                <div className={"delegation-slot filled active-provider"}>
                  <img alt={`${selectedProvider.name} Logo`} src={selectedProvider.logo} />

                  <div className={"delegation-details"}>
                    <div className={"delegation-details-name"}>{selectedProvider.name}</div>
                    <div className={"delegation-details-address"}>
                      {`${selectedProvider.address.substring(0, 8)}...${selectedProvider.address.substring(
                        selectedProvider.address.length - 8
                      )}`}
                    </div>
                  </div>

                  <div className={"delegation-value"}>{selectedProviderValue}%</div>
                </div>

                {openManageProviderModal && (
                  <Slider
                    tooltip={{ open: false }}
                    onChange={(e) => setSelectedProviderValue(e)}
                    marks={{ 0: "0%", [delegationRemaining]: delegationRemaining + "%" }}
                    min={0}
                    max={delegationRemaining}
                    defaultValue={selectedProviderValue}
                  />
                )}
              </div>
            )
          )}
        </Modal>
      </Col>
    </Row>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  setWalletStatus: (status: "connect" | "disconnect") => dispatch(setWalletStatus(status)),
});

const mapStateToProps = (state: any) => ({
  app: state.app,
});

export default connect(mapStateToProps, mapDispatchToProps)(DelegateModule);
