export const ftsoRegistryFLR = {
  address: "0xcD099A11ecd4b02aFF5F17eC46242a9a7cfdA527",
  abi: [
    {
      type: "constructor",
      stateMutability: "nonpayable",
      inputs: [
        { type: "address", name: "_governance", internalType: "address" },
        { type: "address", name: "_addressUpdater", internalType: "address" },
      ],
    },
    {
      type: "event",
      name: "GovernanceCallTimelocked",
      inputs: [
        {
          type: "bytes4",
          name: "selector",
          internalType: "bytes4",
          indexed: false,
        },
        {
          type: "uint256",
          name: "allowedAfterTimestamp",
          internalType: "uint256",
          indexed: false,
        },
        {
          type: "bytes",
          name: "encodedCall",
          internalType: "bytes",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "GovernanceInitialised",
      inputs: [
        {
          type: "address",
          name: "initialGovernance",
          internalType: "address",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "GovernedProductionModeEntered",
      inputs: [
        {
          type: "address",
          name: "governanceSettings",
          internalType: "address",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "TimelockedGovernanceCallCanceled",
      inputs: [
        {
          type: "bytes4",
          name: "selector",
          internalType: "bytes4",
          indexed: false,
        },
        {
          type: "uint256",
          name: "timestamp",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "TimelockedGovernanceCallExecuted",
      inputs: [
        {
          type: "bytes4",
          name: "selector",
          internalType: "bytes4",
          indexed: false,
        },
        {
          type: "uint256",
          name: "timestamp",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "_ftsoIndex", internalType: "uint256" }],
      name: "addFtso",
      inputs: [
        {
          type: "address",
          name: "_ftsoContract",
          internalType: "contract IIFtso",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "cancelGovernanceCall",
      inputs: [{ type: "bytes4", name: "_selector", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "executeGovernanceCall",
      inputs: [{ type: "bytes4", name: "_selector", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "contract IIFtsoManager" }],
      name: "ftsoManager",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "_addressUpdater", internalType: "address" }],
      name: "getAddressUpdater",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address[]",
          name: "_ftsos",
          internalType: "contract IIFtso[]",
        },
      ],
      name: "getAllFtsos",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint256", name: "_price", internalType: "uint256" },
        { type: "uint256", name: "_timestamp", internalType: "uint256" },
      ],
      name: "getCurrentPrice",
      inputs: [{ type: "string", name: "_symbol", internalType: "string" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint256", name: "_price", internalType: "uint256" },
        { type: "uint256", name: "_timestamp", internalType: "uint256" },
      ],
      name: "getCurrentPrice",
      inputs: [{ type: "uint256", name: "_assetIndex", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address",
          name: "_activeFtso",
          internalType: "contract IIFtso",
        },
      ],
      name: "getFtso",
      inputs: [{ type: "uint256", name: "_assetIndex", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address",
          name: "_activeFtso",
          internalType: "contract IIFtso",
        },
      ],
      name: "getFtsoBySymbol",
      inputs: [{ type: "string", name: "_symbol", internalType: "string" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address[5]",
          name: "_ftsoAddressHistory",
          internalType: "contract IIFtso[5]",
        },
      ],
      name: "getFtsoHistory",
      inputs: [{ type: "uint256", name: "_assetIndex", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "_assetIndex", internalType: "uint256" }],
      name: "getFtsoIndex",
      inputs: [{ type: "string", name: "_symbol", internalType: "string" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "string", name: "_symbol", internalType: "string" }],
      name: "getFtsoSymbol",
      inputs: [{ type: "uint256", name: "_assetIndex", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address[]",
          name: "_ftsos",
          internalType: "contract IFtsoGenesis[]",
        },
      ],
      name: "getFtsos",
      inputs: [{ type: "uint256[]", name: "_assetIndices", internalType: "uint256[]" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address[]",
          name: "_ftsos",
          internalType: "contract IIFtso[]",
        },
      ],
      name: "getSupportedFtsos",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256[]",
          name: "_supportedIndices",
          internalType: "uint256[]",
        },
      ],
      name: "getSupportedIndices",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256[]",
          name: "_supportedIndices",
          internalType: "uint256[]",
        },
        {
          type: "address[]",
          name: "_ftsos",
          internalType: "contract IIFtso[]",
        },
      ],
      name: "getSupportedIndicesAndFtsos",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256[]",
          name: "_supportedIndices",
          internalType: "uint256[]",
        },
        {
          type: "string[]",
          name: "_supportedSymbols",
          internalType: "string[]",
        },
      ],
      name: "getSupportedIndicesAndSymbols",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256[]",
          name: "_supportedIndices",
          internalType: "uint256[]",
        },
        {
          type: "string[]",
          name: "_supportedSymbols",
          internalType: "string[]",
        },
        {
          type: "address[]",
          name: "_ftsos",
          internalType: "contract IIFtso[]",
        },
      ],
      name: "getSupportedIndicesSymbolsAndFtsos",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "string[]",
          name: "_supportedSymbols",
          internalType: "string[]",
        },
      ],
      name: "getSupportedSymbols",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "string[]",
          name: "_supportedSymbols",
          internalType: "string[]",
        },
        {
          type: "address[]",
          name: "_ftsos",
          internalType: "contract IIFtso[]",
        },
      ],
      name: "getSupportedSymbolsAndFtsos",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "governance",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address",
          name: "",
          internalType: "contract IGovernanceSettings",
        },
      ],
      name: "governanceSettings",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "initialise",
      inputs: [
        {
          type: "address",
          name: "_initialGovernance",
          internalType: "address",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "productionMode",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "removeFtso",
      inputs: [{ type: "address", name: "_ftso", internalType: "contract IIFtso" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "switchToProductionMode",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256",
          name: "allowedAfterTimestamp",
          internalType: "uint256",
        },
        { type: "bytes", name: "encodedCall", internalType: "bytes" },
      ],
      name: "timelockedCalls",
      inputs: [{ type: "bytes4", name: "", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "updateContractAddresses",
      inputs: [
        {
          type: "bytes32[]",
          name: "_contractNameHashes",
          internalType: "bytes32[]",
        },
        {
          type: "address[]",
          name: "_contractAddresses",
          internalType: "address[]",
        },
      ],
    },
  ],
};

export const ftsoRewardManagerFLR = {
  address: "0x6D55E24Dc2d3bD2Fc5Ae1fcCD1A73bc5f18A8A30",
  abi: [
    {
      type: "constructor",
      stateMutability: "nonpayable",
      inputs: [
        { type: "address", name: "_governance", internalType: "address" },
        { type: "address", name: "_addressUpdater", internalType: "address" },
        {
          type: "address",
          name: "_oldFtsoRewardManager",
          internalType: "address",
        },
        {
          type: "uint256",
          name: "_feePercentageUpdateOffset",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_defaultFeePercentage",
          internalType: "uint256",
        },
      ],
    },
    {
      type: "event",
      name: "AllowedClaimRecipientsChanged",
      inputs: [
        {
          type: "address",
          name: "rewardOwner",
          internalType: "address",
          indexed: false,
        },
        {
          type: "address[]",
          name: "recipients",
          internalType: "address[]",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "ClaimExecutorsChanged",
      inputs: [
        {
          type: "address",
          name: "rewardOwner",
          internalType: "address",
          indexed: false,
        },
        {
          type: "address[]",
          name: "executors",
          internalType: "address[]",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "DailyAuthorizedInflationSet",
      inputs: [
        {
          type: "uint256",
          name: "authorizedAmountWei",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "FeePercentageChanged",
      inputs: [
        {
          type: "address",
          name: "dataProvider",
          internalType: "address",
          indexed: true,
        },
        {
          type: "uint256",
          name: "value",
          internalType: "uint256",
          indexed: false,
        },
        {
          type: "uint256",
          name: "validFromEpoch",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "GovernanceCallTimelocked",
      inputs: [
        {
          type: "bytes4",
          name: "selector",
          internalType: "bytes4",
          indexed: false,
        },
        {
          type: "uint256",
          name: "allowedAfterTimestamp",
          internalType: "uint256",
          indexed: false,
        },
        {
          type: "bytes",
          name: "encodedCall",
          internalType: "bytes",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "GovernanceInitialised",
      inputs: [
        {
          type: "address",
          name: "initialGovernance",
          internalType: "address",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "GovernedProductionModeEntered",
      inputs: [
        {
          type: "address",
          name: "governanceSettings",
          internalType: "address",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "InflationReceived",
      inputs: [
        {
          type: "uint256",
          name: "amountReceivedWei",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "RewardClaimed",
      inputs: [
        {
          type: "address",
          name: "dataProvider",
          internalType: "address",
          indexed: true,
        },
        {
          type: "address",
          name: "whoClaimed",
          internalType: "address",
          indexed: true,
        },
        {
          type: "address",
          name: "sentTo",
          internalType: "address",
          indexed: true,
        },
        {
          type: "uint256",
          name: "rewardEpoch",
          internalType: "uint256",
          indexed: false,
        },
        {
          type: "uint256",
          name: "amount",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "RewardClaimsEnabled",
      inputs: [
        {
          type: "uint256",
          name: "rewardEpochId",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "RewardClaimsExpired",
      inputs: [
        {
          type: "uint256",
          name: "rewardEpochId",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "RewardsBurned",
      inputs: [
        {
          type: "uint256",
          name: "amountBurnedWei",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "RewardsDistributed",
      inputs: [
        {
          type: "address",
          name: "ftso",
          internalType: "address",
          indexed: true,
        },
        {
          type: "uint256",
          name: "epochId",
          internalType: "uint256",
          indexed: false,
        },
        {
          type: "address[]",
          name: "addresses",
          internalType: "address[]",
          indexed: false,
        },
        {
          type: "uint256[]",
          name: "rewards",
          internalType: "uint256[]",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "TimelockedGovernanceCallCanceled",
      inputs: [
        {
          type: "bytes4",
          name: "selector",
          internalType: "bytes4",
          indexed: false,
        },
        {
          type: "uint256",
          name: "timestamp",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "TimelockedGovernanceCallExecuted",
      inputs: [
        {
          type: "bytes4",
          name: "selector",
          internalType: "bytes4",
          indexed: false,
        },
        {
          type: "uint256",
          name: "timestamp",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "UnearnedRewardsAccrued",
      inputs: [
        {
          type: "uint256",
          name: "epochId",
          internalType: "uint256",
          indexed: false,
        },
        {
          type: "uint256",
          name: "reward",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "accrueUnearnedRewards",
      inputs: [
        { type: "uint256", name: "_epochId", internalType: "uint256" },
        {
          type: "uint256",
          name: "_priceEpochDurationSeconds",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_priceEpochEndTime",
          internalType: "uint256",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "activate",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "active",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address[]", name: "", internalType: "address[]" }],
      name: "allowedClaimRecipients",
      inputs: [{ type: "address", name: "_rewardOwner", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "cancelGovernanceCall",
      inputs: [{ type: "bytes4", name: "_selector", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "_rewardAmount", internalType: "uint256" }],
      name: "claimAndWrapReward",
      inputs: [
        {
          type: "address",
          name: "_recipient",
          internalType: "address payable",
        },
        { type: "uint256[]", name: "_rewardEpochs", internalType: "uint256[]" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "_rewardAmount", internalType: "uint256" }],
      name: "claimAndWrapRewardByExecutor",
      inputs: [
        { type: "address", name: "_rewardOwner", internalType: "address" },
        {
          type: "address",
          name: "_recipient",
          internalType: "address payable",
        },
        { type: "uint256[]", name: "_rewardEpochs", internalType: "uint256[]" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "_rewardAmount", internalType: "uint256" }],
      name: "claimAndWrapRewardFromDataProviders",
      inputs: [
        {
          type: "address",
          name: "_recipient",
          internalType: "address payable",
        },
        { type: "uint256[]", name: "_rewardEpochs", internalType: "uint256[]" },
        {
          type: "address[]",
          name: "_dataProviders",
          internalType: "address[]",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "_rewardAmount", internalType: "uint256" }],
      name: "claimAndWrapRewardFromDataProvidersByExecutor",
      inputs: [
        { type: "address", name: "_rewardOwner", internalType: "address" },
        {
          type: "address",
          name: "_recipient",
          internalType: "address payable",
        },
        { type: "uint256[]", name: "_rewardEpochs", internalType: "uint256[]" },
        {
          type: "address[]",
          name: "_dataProviders",
          internalType: "address[]",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address[]", name: "", internalType: "address[]" }],
      name: "claimExecutors",
      inputs: [{ type: "address", name: "_rewardOwner", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "_rewardAmount", internalType: "uint256" }],
      name: "claimReward",
      inputs: [
        {
          type: "address",
          name: "_recipient",
          internalType: "address payable",
        },
        { type: "uint256[]", name: "_rewardEpochs", internalType: "uint256[]" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "_rewardAmount", internalType: "uint256" }],
      name: "claimRewardFromDataProviders",
      inputs: [
        {
          type: "address",
          name: "_recipient",
          internalType: "address payable",
        },
        { type: "uint256[]", name: "_rewardEpochs", internalType: "uint256[]" },
        {
          type: "address[]",
          name: "_dataProviders",
          internalType: "address[]",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "closeExpiredRewardEpoch",
      inputs: [{ type: "uint256", name: "_rewardEpoch", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "deactivate",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "defaultFeePercentage",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "distributeRewards",
      inputs: [
        { type: "address[]", name: "_addresses", internalType: "address[]" },
        { type: "uint256[]", name: "_weights", internalType: "uint256[]" },
        { type: "uint256", name: "_totalWeight", internalType: "uint256" },
        { type: "uint256", name: "_epochId", internalType: "uint256" },
        { type: "address", name: "_ftso", internalType: "address" },
        {
          type: "uint256",
          name: "_priceEpochDurationSeconds",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_currentRewardEpoch",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_priceEpochEndTime",
          internalType: "uint256",
        },
        { type: "uint256", name: "_votePowerBlock", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "enableClaims",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "executeGovernanceCall",
      inputs: [{ type: "bytes4", name: "_selector", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "feePercentageUpdateOffset",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "firstClaimableRewardEpoch",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "contract IIFtsoManager" }],
      name: "ftsoManager",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "_addressUpdater", internalType: "address" }],
      name: "getAddressUpdater",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "bool", name: "_claimed", internalType: "bool" },
        { type: "uint256", name: "_amount", internalType: "uint256" },
      ],
      name: "getClaimedReward",
      inputs: [
        { type: "uint256", name: "_rewardEpoch", internalType: "uint256" },
        { type: "address", name: "_dataProvider", internalType: "address" },
        { type: "address", name: "_claimer", internalType: "address" },
      ],
    },
    {
      type: "function",
      stateMutability: "pure",
      outputs: [{ type: "string", name: "", internalType: "string" }],
      name: "getContractName",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "getCurrentRewardEpoch",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "getDataProviderCurrentFeePercentage",
      inputs: [{ type: "address", name: "_dataProvider", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256",
          name: "_feePercentageBIPS",
          internalType: "uint256",
        },
      ],
      name: "getDataProviderFeePercentage",
      inputs: [
        { type: "address", name: "_dataProvider", internalType: "address" },
        { type: "uint256", name: "_rewardEpoch", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint256", name: "_rewardAmount", internalType: "uint256" },
        {
          type: "uint256",
          name: "_votePowerIgnoringRevocation",
          internalType: "uint256",
        },
      ],
      name: "getDataProviderPerformanceInfo",
      inputs: [
        { type: "uint256", name: "_rewardEpoch", internalType: "uint256" },
        { type: "address", name: "_dataProvider", internalType: "address" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256[]",
          name: "_feePercentageBIPS",
          internalType: "uint256[]",
        },
        {
          type: "uint256[]",
          name: "_validFromEpoch",
          internalType: "uint256[]",
        },
        { type: "bool[]", name: "_fixed", internalType: "bool[]" },
      ],
      name: "getDataProviderScheduledFeePercentageChanges",
      inputs: [{ type: "address", name: "_dataProvider", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint256", name: "_totalReward", internalType: "uint256" },
        { type: "uint256", name: "_claimedReward", internalType: "uint256" },
      ],
      name: "getEpochReward",
      inputs: [{ type: "uint256", name: "_rewardEpoch", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint256", name: "_startEpochId", internalType: "uint256" },
        { type: "uint256", name: "_endEpochId", internalType: "uint256" },
      ],
      name: "getEpochsWithClaimableRewards",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256[]", name: "_epochIds", internalType: "uint256[]" }],
      name: "getEpochsWithUnclaimedRewards",
      inputs: [{ type: "address", name: "_beneficiary", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "getInflationAddress",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256",
          name: "_initialRewardEpoch",
          internalType: "uint256",
        },
      ],
      name: "getInitialRewardEpoch",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "getRewardEpochToExpireNext",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "getRewardEpochVotePowerBlock",
      inputs: [{ type: "uint256", name: "_rewardEpoch", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address[]",
          name: "_dataProviders",
          internalType: "address[]",
        },
        {
          type: "uint256[]",
          name: "_rewardAmounts",
          internalType: "uint256[]",
        },
        { type: "bool[]", name: "_claimed", internalType: "bool[]" },
        { type: "bool", name: "_claimable", internalType: "bool" },
      ],
      name: "getStateOfRewards",
      inputs: [
        { type: "address", name: "_beneficiary", internalType: "address" },
        { type: "uint256", name: "_rewardEpoch", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256[]",
          name: "_rewardAmounts",
          internalType: "uint256[]",
        },
        { type: "bool[]", name: "_claimed", internalType: "bool[]" },
        { type: "bool", name: "_claimable", internalType: "bool" },
      ],
      name: "getStateOfRewardsFromDataProviders",
      inputs: [
        { type: "address", name: "_beneficiary", internalType: "address" },
        { type: "uint256", name: "_rewardEpoch", internalType: "uint256" },
        {
          type: "address[]",
          name: "_dataProviders",
          internalType: "address[]",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint256", name: "_lockedFundsWei", internalType: "uint256" },
        {
          type: "uint256",
          name: "_totalInflationAuthorizedWei",
          internalType: "uint256",
        },
        { type: "uint256", name: "_totalClaimedWei", internalType: "uint256" },
      ],
      name: "getTokenPoolSupplyData",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint256", name: "_totalAwardedWei", internalType: "uint256" },
        { type: "uint256", name: "_totalClaimedWei", internalType: "uint256" },
        { type: "uint256", name: "_totalExpiredWei", internalType: "uint256" },
        { type: "uint256", name: "_totalUnearnedWei", internalType: "uint256" },
        { type: "uint256", name: "_totalBurnedWei", internalType: "uint256" },
        {
          type: "uint256",
          name: "_totalInflationAuthorizedWei",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_totalInflationReceivedWei",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_totalSelfDestructReceivedWei",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_lastInflationAuthorizationReceivedTs",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_dailyAuthorizedInflation",
          internalType: "uint256",
        },
      ],
      name: "getTotals",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint256", name: "_amount", internalType: "uint256" },
        { type: "uint256", name: "_weight", internalType: "uint256" },
      ],
      name: "getUnclaimedReward",
      inputs: [
        { type: "uint256", name: "_rewardEpoch", internalType: "uint256" },
        { type: "address", name: "_dataProvider", internalType: "address" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "governance",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address",
          name: "",
          internalType: "contract IGovernanceSettings",
        },
      ],
      name: "governanceSettings",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "initialise",
      inputs: [
        {
          type: "address",
          name: "_initialGovernance",
          internalType: "address",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "newFtsoRewardManager",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "oldFtsoRewardManager",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "productionMode",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "receiveInflation",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setAllowedClaimRecipients",
      inputs: [{ type: "address[]", name: "_recipients", internalType: "address[]" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setClaimExecutors",
      inputs: [{ type: "address[]", name: "_executors", internalType: "address[]" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setDailyAuthorizedInflation",
      inputs: [{ type: "uint256", name: "_toAuthorizeWei", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "setDataProviderFeePercentage",
      inputs: [
        {
          type: "uint256",
          name: "_feePercentageBIPS",
          internalType: "uint256",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setInitialRewardData",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setNewFtsoRewardManager",
      inputs: [
        {
          type: "address",
          name: "_newFtsoRewardManager",
          internalType: "address",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "contract Supply" }],
      name: "supply",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "switchToProductionMode",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256",
          name: "allowedAfterTimestamp",
          internalType: "uint256",
        },
        { type: "bytes", name: "encodedCall", internalType: "bytes" },
      ],
      name: "timelockedCalls",
      inputs: [{ type: "bytes4", name: "", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "updateContractAddresses",
      inputs: [
        {
          type: "bytes32[]",
          name: "_contractNameHashes",
          internalType: "bytes32[]",
        },
        {
          type: "address[]",
          name: "_contractAddresses",
          internalType: "address[]",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "contract WNat" }],
      name: "wNat",
      inputs: [],
    },
  ],
};

export const supplyFLR = {
  address: "0x8577D2D50827bCE2EC1dc9Cf9F1199826781D5AF",
  abi: [
    {
      type: "constructor",
      stateMutability: "nonpayable",
      inputs: [
        { type: "address", name: "_governance", internalType: "address" },
        { type: "address", name: "_addressUpdater", internalType: "address" },
        {
          type: "uint256",
          name: "_initialGenesisAmountWei",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_totalExcludedSupplyWei",
          internalType: "uint256",
        },
        {
          type: "address[]",
          name: "_tokenPools",
          internalType: "contract IITokenPool[]",
        },
      ],
    },
    {
      type: "event",
      name: "AuthorizedInflationUpdateError",
      inputs: [
        {
          type: "uint256",
          name: "actual",
          internalType: "uint256",
          indexed: false,
        },
        {
          type: "uint256",
          name: "expected",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "GovernanceCallTimelocked",
      inputs: [
        {
          type: "bytes4",
          name: "selector",
          internalType: "bytes4",
          indexed: false,
        },
        {
          type: "uint256",
          name: "allowedAfterTimestamp",
          internalType: "uint256",
          indexed: false,
        },
        {
          type: "bytes",
          name: "encodedCall",
          internalType: "bytes",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "GovernanceInitialised",
      inputs: [
        {
          type: "address",
          name: "initialGovernance",
          internalType: "address",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "GovernedProductionModeEntered",
      inputs: [
        {
          type: "address",
          name: "governanceSettings",
          internalType: "address",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "TimelockedGovernanceCallCanceled",
      inputs: [
        {
          type: "bytes4",
          name: "selector",
          internalType: "bytes4",
          indexed: false,
        },
        {
          type: "uint256",
          name: "timestamp",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "TimelockedGovernanceCallExecuted",
      inputs: [
        {
          type: "bytes4",
          name: "selector",
          internalType: "bytes4",
          indexed: false,
        },
        {
          type: "uint256",
          name: "timestamp",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "addTokenPool",
      inputs: [
        {
          type: "address",
          name: "_tokenPool",
          internalType: "contract IITokenPool",
        },
        {
          type: "uint256",
          name: "_increaseDistributedSupplyByAmountWei",
          internalType: "uint256",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "pure",
      outputs: [{ type: "address", name: "", internalType: "address payable" }],
      name: "burnAddress",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "cancelGovernanceCall",
      inputs: [{ type: "bytes4", name: "_selector", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "decreaseDistributedSupply",
      inputs: [{ type: "uint256", name: "_amountWei", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "distributedExcludedSupplyWei",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "executeGovernanceCall",
      inputs: [{ type: "bytes4", name: "_selector", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "_addressUpdater", internalType: "address" }],
      name: "getAddressUpdater",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256",
          name: "_circulatingSupplyWei",
          internalType: "uint256",
        },
      ],
      name: "getCirculatingSupplyAt",
      inputs: [{ type: "uint256", name: "_blockNumber", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [
        {
          type: "uint256",
          name: "_circulatingSupplyWei",
          internalType: "uint256",
        },
      ],
      name: "getCirculatingSupplyAtCached",
      inputs: [{ type: "uint256", name: "_blockNumber", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256",
          name: "_inflatableBalanceWei",
          internalType: "uint256",
        },
      ],
      name: "getInflatableBalance",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "governance",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address",
          name: "",
          internalType: "contract IGovernanceSettings",
        },
      ],
      name: "governanceSettings",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "increaseDistributedSupply",
      inputs: [{ type: "uint256", name: "_amountWei", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "inflation",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "initialGenesisAmountWei",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "initialise",
      inputs: [
        {
          type: "address",
          name: "_initialGovernance",
          internalType: "address",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "productionMode",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "switchToProductionMode",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256",
          name: "allowedAfterTimestamp",
          internalType: "uint256",
        },
        { type: "bytes", name: "encodedCall", internalType: "bytes" },
      ],
      name: "timelockedCalls",
      inputs: [{ type: "bytes4", name: "", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address",
          name: "tokenPool",
          internalType: "contract IITokenPool",
        },
        { type: "uint256", name: "totalLockedWei", internalType: "uint256" },
        {
          type: "uint256",
          name: "totalInflationAuthorizedWei",
          internalType: "uint256",
        },
        { type: "uint256", name: "totalClaimedWei", internalType: "uint256" },
      ],
      name: "tokenPools",
      inputs: [{ type: "uint256", name: "", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "totalClaimedWei",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "totalExcludedSupplyWei",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "totalInflationAuthorizedWei",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "totalLockedWei",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "updateAuthorizedInflationAndCirculatingSupply",
      inputs: [
        {
          type: "uint256",
          name: "_inflationAuthorizedWei",
          internalType: "uint256",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "updateCirculatingSupply",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "updateContractAddresses",
      inputs: [
        {
          type: "bytes32[]",
          name: "_contractNameHashes",
          internalType: "bytes32[]",
        },
        {
          type: "address[]",
          name: "_contractAddresses",
          internalType: "address[]",
        },
      ],
    },
  ],
};

export const VPContractFLR = {
  address: "0x50eDeAA0F6772c899276dDc1b515f4C81D529E6c",
  abi: [
    {
      type: "constructor",
      stateMutability: "nonpayable",
      inputs: [
        {
          type: "address",
          name: "_ownerToken",
          internalType: "contract IVPToken",
        },
        { type: "bool", name: "_isReplacement", internalType: "bool" },
      ],
    },
    {
      type: "event",
      name: "CreatedVotePowerCache",
      inputs: [
        {
          type: "address",
          name: "_owner",
          internalType: "address",
          indexed: false,
        },
        {
          type: "uint256",
          name: "_blockNumber",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Delegate",
      inputs: [
        {
          type: "address",
          name: "from",
          internalType: "address",
          indexed: true,
        },
        { type: "address", name: "to", internalType: "address", indexed: true },
        {
          type: "uint256",
          name: "priorVotePower",
          internalType: "uint256",
          indexed: false,
        },
        {
          type: "uint256",
          name: "newVotePower",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Revoke",
      inputs: [
        {
          type: "address",
          name: "delegator",
          internalType: "address",
          indexed: true,
        },
        {
          type: "address",
          name: "delegatee",
          internalType: "address",
          indexed: true,
        },
        {
          type: "uint256",
          name: "votePower",
          internalType: "uint256",
          indexed: false,
        },
        {
          type: "uint256",
          name: "blockNumber",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256[]", name: "_votePowers", internalType: "uint256[]" }],
      name: "batchVotePowerOfAt",
      inputs: [
        { type: "address[]", name: "_owners", internalType: "address[]" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "cleanerContract",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "cleanupBlockNumber",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "delegate",
      inputs: [
        { type: "address", name: "_from", internalType: "address" },
        { type: "address", name: "_to", internalType: "address" },
        { type: "uint256", name: "_balance", internalType: "uint256" },
        { type: "uint256", name: "_bips", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "delegateExplicit",
      inputs: [
        { type: "address", name: "_from", internalType: "address" },
        { type: "address", name: "_to", internalType: "address" },
        { type: "uint256", name: "_balance", internalType: "uint256" },
        { type: "uint256", name: "_amount", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address[]",
          name: "_delegateAddresses",
          internalType: "address[]",
        },
        { type: "uint256[]", name: "_bips", internalType: "uint256[]" },
        { type: "uint256", name: "_count", internalType: "uint256" },
        { type: "uint256", name: "_delegationMode", internalType: "uint256" },
      ],
      name: "delegatesOf",
      inputs: [{ type: "address", name: "_owner", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address[]",
          name: "_delegateAddresses",
          internalType: "address[]",
        },
        { type: "uint256[]", name: "_bips", internalType: "uint256[]" },
        { type: "uint256", name: "_count", internalType: "uint256" },
        { type: "uint256", name: "_delegationMode", internalType: "uint256" },
      ],
      name: "delegatesOfAt",
      inputs: [
        { type: "address", name: "_owner", internalType: "address" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "delegationModeOf",
      inputs: [{ type: "address", name: "_who", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "explicitDelegationHistoryCleanup",
      inputs: [
        { type: "address", name: "_from", internalType: "address" },
        { type: "address", name: "_to", internalType: "address" },
        { type: "uint256", name: "_count", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "isReplacement",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "contract IVPToken" }],
      name: "ownerToken",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "percentageDelegationHistoryCleanup",
      inputs: [
        { type: "address", name: "_owner", internalType: "address" },
        { type: "uint256", name: "_count", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "revocationCleanup",
      inputs: [
        { type: "address", name: "_from", internalType: "address" },
        { type: "address", name: "_to", internalType: "address" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "revokeDelegationAt",
      inputs: [
        { type: "address", name: "_from", internalType: "address" },
        { type: "address", name: "_to", internalType: "address" },
        { type: "uint256", name: "_balance", internalType: "uint256" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setCleanerContract",
      inputs: [{ type: "address", name: "_cleanerContract", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setCleanupBlockNumber",
      inputs: [{ type: "uint256", name: "_blockNumber", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "undelegateAll",
      inputs: [
        { type: "address", name: "_from", internalType: "address" },
        { type: "uint256", name: "_balance", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "undelegateAllExplicit",
      inputs: [
        { type: "address", name: "_from", internalType: "address" },
        {
          type: "address[]",
          name: "_delegateAddresses",
          internalType: "address[]",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "undelegatedVotePowerOf",
      inputs: [
        { type: "address", name: "_owner", internalType: "address" },
        { type: "uint256", name: "_balance", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "undelegatedVotePowerOfAt",
      inputs: [
        { type: "address", name: "_owner", internalType: "address" },
        { type: "uint256", name: "_balance", internalType: "uint256" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "updateAtTokenTransfer",
      inputs: [
        { type: "address", name: "_from", internalType: "address" },
        { type: "address", name: "_to", internalType: "address" },
        { type: "uint256", name: "_fromBalance", internalType: "uint256" },
        { type: "uint256", name: "_toBalance", internalType: "uint256" },
        { type: "uint256", name: "_amount", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "votePowerCacheCleanup",
      inputs: [
        { type: "address", name: "_owner", internalType: "address" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "votePowerFromTo",
      inputs: [
        { type: "address", name: "_from", internalType: "address" },
        { type: "address", name: "_to", internalType: "address" },
        { type: "uint256", name: "_balance", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "votePowerFromToAt",
      inputs: [
        { type: "address", name: "_from", internalType: "address" },
        { type: "address", name: "_to", internalType: "address" },
        { type: "uint256", name: "_balance", internalType: "uint256" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "votePowerHistoryCleanup",
      inputs: [
        { type: "address", name: "_owner", internalType: "address" },
        { type: "uint256", name: "_count", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "votePowerOf",
      inputs: [{ type: "address", name: "_who", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "votePowerOfAt",
      inputs: [
        { type: "address", name: "_who", internalType: "address" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "votePowerOfAtCached",
      inputs: [
        { type: "address", name: "_who", internalType: "address" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "votePowerOfAtIgnoringRevocation",
      inputs: [
        { type: "address", name: "_who", internalType: "address" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
  ],
};

export const ftsoManagerFLR = {
  address: "0xAB7C7da1Ff2b25D0867908ddFa52827570e02894",
  abi: [
    {
      type: "constructor",
      stateMutability: "nonpayable",
      inputs: [
        { type: "address", name: "_governance", internalType: "address" },
        {
          type: "address",
          name: "_flareDaemon",
          internalType: "contract FlareDaemon",
        },
        { type: "address", name: "_addressUpdater", internalType: "address" },
        {
          type: "address",
          name: "_priceSubmitter",
          internalType: "contract IIPriceSubmitter",
        },
        {
          type: "address",
          name: "_oldFtsoManager",
          internalType: "contract IIFtsoManagerV1",
        },
        {
          type: "uint256",
          name: "_firstPriceEpochStartTs",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_priceEpochDurationSeconds",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_revealEpochDurationSeconds",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_firstRewardEpochStartTs",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_rewardEpochDurationSeconds",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_votePowerIntervalFraction",
          internalType: "uint256",
        },
      ],
    },
    {
      type: "event",
      name: "AccruingUnearnedRewardsFailed",
      inputs: [
        {
          type: "uint256",
          name: "epochId",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "CleanupBlockNumberManagerFailedForBlock",
      inputs: [
        {
          type: "uint256",
          name: "blockNumber",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "ClosingExpiredRewardEpochFailed",
      inputs: [
        {
          type: "uint256",
          name: "rewardEpoch",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "ContractRevertError",
      inputs: [
        {
          type: "address",
          name: "theContract",
          internalType: "address",
          indexed: false,
        },
        {
          type: "uint256",
          name: "atBlock",
          internalType: "uint256",
          indexed: false,
        },
        {
          type: "string",
          name: "theMessage",
          internalType: "string",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "DistributingRewardsFailed",
      inputs: [
        {
          type: "address",
          name: "ftso",
          internalType: "address",
          indexed: false,
        },
        {
          type: "uint256",
          name: "epochId",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "FallbackMode",
      inputs: [
        {
          type: "bool",
          name: "fallbackMode",
          internalType: "bool",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "FinalizingPriceEpochFailed",
      inputs: [
        {
          type: "address",
          name: "ftso",
          internalType: "contract IIFtso",
          indexed: false,
        },
        {
          type: "uint256",
          name: "epochId",
          internalType: "uint256",
          indexed: false,
        },
        {
          type: "uint8",
          name: "failingType",
          internalType: "enum IFtso.PriceFinalizationType",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "FtsoAdded",
      inputs: [
        {
          type: "address",
          name: "ftso",
          internalType: "contract IIFtso",
          indexed: false,
        },
        { type: "bool", name: "add", internalType: "bool", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "FtsoDeactivationFailed",
      inputs: [
        {
          type: "address",
          name: "ftso",
          internalType: "contract IIFtso",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "FtsoFallbackMode",
      inputs: [
        {
          type: "address",
          name: "ftso",
          internalType: "contract IIFtso",
          indexed: false,
        },
        {
          type: "bool",
          name: "fallbackMode",
          internalType: "bool",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "GovernanceCallTimelocked",
      inputs: [
        {
          type: "bytes4",
          name: "selector",
          internalType: "bytes4",
          indexed: false,
        },
        {
          type: "uint256",
          name: "allowedAfterTimestamp",
          internalType: "uint256",
          indexed: false,
        },
        {
          type: "bytes",
          name: "encodedCall",
          internalType: "bytes",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "GovernanceInitialised",
      inputs: [
        {
          type: "address",
          name: "initialGovernance",
          internalType: "address",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "GovernedProductionModeEntered",
      inputs: [
        {
          type: "address",
          name: "governanceSettings",
          internalType: "address",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "InitializingCurrentEpochStateForRevealFailed",
      inputs: [
        {
          type: "address",
          name: "ftso",
          internalType: "contract IIFtso",
          indexed: false,
        },
        {
          type: "uint256",
          name: "epochId",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "PriceEpochFinalized",
      inputs: [
        {
          type: "address",
          name: "chosenFtso",
          internalType: "address",
          indexed: false,
        },
        {
          type: "uint256",
          name: "rewardEpochId",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "RewardEpochFinalized",
      inputs: [
        {
          type: "uint256",
          name: "votepowerBlock",
          internalType: "uint256",
          indexed: false,
        },
        {
          type: "uint256",
          name: "startBlock",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "TimelockedGovernanceCallCanceled",
      inputs: [
        {
          type: "bytes4",
          name: "selector",
          internalType: "bytes4",
          indexed: false,
        },
        {
          type: "uint256",
          name: "timestamp",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "TimelockedGovernanceCallExecuted",
      inputs: [
        {
          type: "bytes4",
          name: "selector",
          internalType: "bytes4",
          indexed: false,
        },
        {
          type: "uint256",
          name: "timestamp",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "UpdatingActiveValidatorsTriggerFailed",
      inputs: [
        {
          type: "uint256",
          name: "rewardEpoch",
          internalType: "uint256",
          indexed: false,
        },
      ],
      anonymous: false,
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "MAX_TRUSTED_ADDRESSES_LENGTH",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "activate",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "active",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "addFtso",
      inputs: [{ type: "address", name: "_ftso", internalType: "contract IIFtso" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "addFtsosBulk",
      inputs: [
        {
          type: "address[]",
          name: "_ftsos",
          internalType: "contract IIFtso[]",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "cancelGovernanceCall",
      inputs: [{ type: "bytes4", name: "_selector", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address",
          name: "",
          internalType: "contract CleanupBlockNumberManager",
        },
      ],
      name: "cleanupBlockNumberManager",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "currentRewardEpochEnds",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "daemonize",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "deactivateFtsos",
      inputs: [
        {
          type: "address[]",
          name: "_ftsos",
          internalType: "contract IIFtso[]",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint192",
          name: "totalRevertedErrors",
          internalType: "uint192",
        },
        { type: "uint64", name: "lastErrorTypeIndex", internalType: "uint64" },
      ],
      name: "errorData",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "executeGovernanceCall",
      inputs: [{ type: "bytes4", name: "_selector", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "contract FlareDaemon" }],
      name: "flareDaemon",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address",
          name: "ftsoRegistry",
          internalType: "contract IIFtsoRegistry",
        },
        {
          type: "address",
          name: "voterWhitelister",
          internalType: "contract IIVoterWhitelister",
        },
      ],
      name: "ftsoManagement",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "contract IIFtsoRegistry" }],
      name: "ftsoRegistry",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "_addressUpdater", internalType: "address" }],
      name: "getAddressUpdater",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "pure",
      outputs: [{ type: "string", name: "", internalType: "string" }],
      name: "getContractName",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint256", name: "_priceEpochId", internalType: "uint256" },
        {
          type: "uint256",
          name: "_priceEpochStartTimestamp",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_priceEpochEndTimestamp",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_priceEpochRevealEndTimestamp",
          internalType: "uint256",
        },
        { type: "uint256", name: "_currentTimestamp", internalType: "uint256" },
      ],
      name: "getCurrentPriceEpochData",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "_priceEpochId", internalType: "uint256" }],
      name: "getCurrentPriceEpochId",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "getCurrentRewardEpoch",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "bool", name: "_fallbackMode", internalType: "bool" },
        {
          type: "address[]",
          name: "_ftsos",
          internalType: "contract IIFtso[]",
        },
        { type: "bool[]", name: "_ftsoInFallbackMode", internalType: "bool[]" },
      ],
      name: "getFallbackMode",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address[]",
          name: "_ftsos",
          internalType: "contract IIFtso[]",
        },
      ],
      name: "getFtsos",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256",
          name: "_maxVotePowerNatThresholdFraction",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_maxVotePowerAssetThresholdFraction",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_lowAssetUSDThreshold",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_highAssetUSDThreshold",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_highAssetTurnoutThresholdBIPS",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_lowNatTurnoutThresholdBIPS",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_rewardExpiryOffsetSeconds",
          internalType: "uint256",
        },
        {
          type: "address[]",
          name: "_trustedAddresses",
          internalType: "address[]",
        },
        { type: "bool", name: "_initialized", internalType: "bool" },
        { type: "bool", name: "_changed", internalType: "bool" },
      ],
      name: "getGovernanceParameters",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256",
          name: "_lastUnprocessedPriceEpoch",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_lastUnprocessedPriceEpochRevealEnds",
          internalType: "uint256",
        },
        {
          type: "bool",
          name: "_lastUnprocessedPriceEpochInitialized",
          internalType: "bool",
        },
      ],
      name: "getLastUnprocessedPriceEpochData",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256",
          name: "_firstPriceEpochStartTs",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_priceEpochDurationSeconds",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_revealEpochDurationSeconds",
          internalType: "uint256",
        },
      ],
      name: "getPriceEpochConfiguration",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address",
          name: "",
          internalType: "contract IIPriceSubmitter",
        },
      ],
      name: "getPriceSubmitter",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256",
          name: "_firstRewardEpochStartTs",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_rewardEpochDurationSeconds",
          internalType: "uint256",
        },
      ],
      name: "getRewardEpochConfiguration",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "tuple",
          name: "",
          internalType: "struct IIFtsoManager.RewardEpochData",
          components: [
            {
              type: "uint256",
              name: "votepowerBlock",
              internalType: "uint256",
            },
            { type: "uint256", name: "startBlock", internalType: "uint256" },
            {
              type: "uint256",
              name: "startTimestamp",
              internalType: "uint256",
            },
          ],
        },
      ],
      name: "getRewardEpochData",
      inputs: [{ type: "uint256", name: "_rewardEpochId", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "getRewardEpochToExpireNext",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "_votepowerBlock", internalType: "uint256" }],
      name: "getRewardEpochVotePowerBlock",
      inputs: [{ type: "uint256", name: "_rewardEpoch", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "getVotePowerIntervalFraction",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "governance",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address",
          name: "",
          internalType: "contract IGovernanceSettings",
        },
      ],
      name: "governanceSettings",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "initialise",
      inputs: [
        {
          type: "address",
          name: "_initialGovernance",
          internalType: "address",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "lastRewardedFtsoAddress",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "notInitializedFtsos",
      inputs: [{ type: "address", name: "_ftso", internalType: "contract IIFtso" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "contract IIFtsoManagerV1" }],
      name: "oldFtsoManager",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address",
          name: "",
          internalType: "contract IIPriceSubmitter",
        },
      ],
      name: "priceSubmitter",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "productionMode",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "removeFtso",
      inputs: [{ type: "address", name: "_ftso", internalType: "contract IIFtso" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "replaceFtso",
      inputs: [
        {
          type: "address",
          name: "_ftsoToAdd",
          internalType: "contract IIFtso",
        },
        { type: "bool", name: "_copyCurrentPrice", internalType: "bool" },
        { type: "bool", name: "_copyAssetOrAssetFtsos", internalType: "bool" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "replaceFtsosBulk",
      inputs: [
        {
          type: "address[]",
          name: "_ftsosToAdd",
          internalType: "contract IIFtso[]",
        },
        { type: "bool", name: "_copyCurrentPrice", internalType: "bool" },
        { type: "bool", name: "_copyAssetOrAssetFtsos", internalType: "bool" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "rewardEpochDurationSeconds",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint256", name: "_votepowerBlock", internalType: "uint256" },
        { type: "uint256", name: "_startBlock", internalType: "uint256" },
        { type: "uint256", name: "_startTimestamp", internalType: "uint256" },
      ],
      name: "rewardEpochs",
      inputs: [{ type: "uint256", name: "_rewardEpochId", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "rewardEpochsStartTs",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address",
          name: "",
          internalType: "contract IIFtsoRewardManager",
        },
      ],
      name: "rewardManager",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setFallbackMode",
      inputs: [{ type: "bool", name: "_fallbackMode", internalType: "bool" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setFtsoAsset",
      inputs: [
        { type: "address", name: "_ftso", internalType: "contract IIFtso" },
        { type: "address", name: "_asset", internalType: "contract IIVPToken" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setFtsoAssetFtsos",
      inputs: [
        { type: "address", name: "_ftso", internalType: "contract IIFtso" },
        {
          type: "address[]",
          name: "_assetFtsos",
          internalType: "contract IIFtso[]",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setFtsoFallbackMode",
      inputs: [
        { type: "address", name: "_ftso", internalType: "contract IIFtso" },
        { type: "bool", name: "_fallbackMode", internalType: "bool" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setGovernanceParameters",
      inputs: [
        {
          type: "uint256",
          name: "_maxVotePowerNatThresholdFraction",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_maxVotePowerAssetThresholdFraction",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_lowAssetUSDThreshold",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_highAssetUSDThreshold",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_highAssetTurnoutThresholdBIPS",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_lowNatTurnoutThresholdBIPS",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_rewardExpiryOffsetSeconds",
          internalType: "uint256",
        },
        {
          type: "address[]",
          name: "_trustedAddresses",
          internalType: "address[]",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setInitialRewardData",
      inputs: [
        {
          type: "uint256",
          name: "_nextRewardEpochToExpire",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_rewardEpochsLength",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "_currentRewardEpochEnds",
          internalType: "uint256",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setRewardEpochDurationSeconds",
      inputs: [
        {
          type: "uint256",
          name: "_rewardEpochDurationSeconds",
          internalType: "uint256",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setUpdateOnRewardEpochSwitchover",
      inputs: [
        {
          type: "address",
          name: "_updateValidators",
          internalType: "contract IUpdateValidators",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setVotePowerIntervalFraction",
      inputs: [
        {
          type: "uint256",
          name: "_votePowerIntervalFraction",
          internalType: "uint256",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256",
          name: "maxVotePowerNatThresholdFraction",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "maxVotePowerAssetThresholdFraction",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "lowAssetUSDThreshold",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "highAssetUSDThreshold",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "highAssetTurnoutThresholdBIPS",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "lowNatTurnoutThresholdBIPS",
          internalType: "uint256",
        },
        {
          type: "uint256",
          name: "rewardExpiryOffsetSeconds",
          internalType: "uint256",
        },
        { type: "bool", name: "changed", internalType: "bool" },
        { type: "bool", name: "initialized", internalType: "bool" },
      ],
      name: "settings",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256[]",
          name: "_lastErrorBlock",
          internalType: "uint256[]",
        },
        { type: "uint256[]", name: "_numErrors", internalType: "uint256[]" },
        { type: "string[]", name: "_errorString", internalType: "string[]" },
        {
          type: "address[]",
          name: "_erroringContract",
          internalType: "address[]",
        },
        {
          type: "uint256",
          name: "_totalRevertedErrors",
          internalType: "uint256",
        },
      ],
      name: "showLastRevertedError",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256[]",
          name: "_lastErrorBlock",
          internalType: "uint256[]",
        },
        { type: "uint256[]", name: "_numErrors", internalType: "uint256[]" },
        { type: "string[]", name: "_errorString", internalType: "string[]" },
        {
          type: "address[]",
          name: "_erroringContract",
          internalType: "address[]",
        },
        {
          type: "uint256",
          name: "_totalRevertedErrors",
          internalType: "uint256",
        },
      ],
      name: "showRevertedErrors",
      inputs: [
        { type: "uint256", name: "startIndex", internalType: "uint256" },
        {
          type: "uint256",
          name: "numErrorTypesToShow",
          internalType: "uint256",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "contract IISupply" }],
      name: "supply",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "switchToFallbackMode",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "switchToProductionMode",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "uint256",
          name: "allowedAfterTimestamp",
          internalType: "uint256",
        },
        { type: "bytes", name: "encodedCall", internalType: "bytes" },
      ],
      name: "timelockedCalls",
      inputs: [{ type: "bytes4", name: "", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "updateContractAddresses",
      inputs: [
        {
          type: "bytes32[]",
          name: "_contractNameHashes",
          internalType: "bytes32[]",
        },
        {
          type: "address[]",
          name: "_contractAddresses",
          internalType: "address[]",
        },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address",
          name: "",
          internalType: "contract IUpdateValidators",
        },
      ],
      name: "updateOnRewardEpochSwitchover",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        {
          type: "address",
          name: "",
          internalType: "contract IIVoterWhitelister",
        },
      ],
      name: "voterWhitelister",
      inputs: [],
    },
  ],
};

export const priceFinalizedAbiFLR = [
  {
    type: "event",
    name: "PriceFinalized",
    inputs: [
      {
        type: "uint256",
        name: "epochId",
        internalType: "uint256",
        indexed: true,
      },
      {
        type: "uint256",
        name: "price",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "bool",
        name: "rewardedFtso",
        internalType: "bool",
        indexed: false,
      },
      {
        type: "uint256",
        name: "lowRewardPrice",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint256",
        name: "highRewardPrice",
        internalType: "uint256",
        indexed: false,
      },
      {
        type: "uint8",
        name: "finalizationType",
        internalType: "enum IFtso.PriceFinalizationType",
        indexed: false,
      },
      {
        type: "uint256",
        name: "timestamp",
        internalType: "uint256",
        indexed: false,
      },
    ],
    anonymous: false,
  },
];

export const wNatFLR = {
  address: "0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d",
  abi: [
    {
      type: "constructor",
      stateMutability: "nonpayable",
      inputs: [
        { type: "address", name: "_governance", internalType: "address" },
        { type: "string", name: "_name", internalType: "string" },
        { type: "string", name: "_symbol", internalType: "string" },
      ],
    },
    {
      type: "event",
      name: "Approval",
      inputs: [
        { type: "address", name: "owner", internalType: "address", indexed: true },
        { type: "address", name: "spender", internalType: "address", indexed: true },
        { type: "uint256", name: "value", internalType: "uint256", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "CreatedTotalSupplyCache",
      inputs: [{ type: "uint256", name: "_blockNumber", internalType: "uint256", indexed: false }],
      anonymous: false,
    },
    {
      type: "event",
      name: "Deposit",
      inputs: [
        { type: "address", name: "dst", internalType: "address", indexed: true },
        { type: "uint256", name: "amount", internalType: "uint256", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "GovernanceCallTimelocked",
      inputs: [
        { type: "bytes4", name: "selector", internalType: "bytes4", indexed: false },
        { type: "uint256", name: "allowedAfterTimestamp", internalType: "uint256", indexed: false },
        { type: "bytes", name: "encodedCall", internalType: "bytes", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "GovernanceInitialised",
      inputs: [{ type: "address", name: "initialGovernance", internalType: "address", indexed: false }],
      anonymous: false,
    },
    {
      type: "event",
      name: "GovernedProductionModeEntered",
      inputs: [{ type: "address", name: "governanceSettings", internalType: "address", indexed: false }],
      anonymous: false,
    },
    {
      type: "event",
      name: "TimelockedGovernanceCallCanceled",
      inputs: [
        { type: "bytes4", name: "selector", internalType: "bytes4", indexed: false },
        { type: "uint256", name: "timestamp", internalType: "uint256", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "TimelockedGovernanceCallExecuted",
      inputs: [
        { type: "bytes4", name: "selector", internalType: "bytes4", indexed: false },
        { type: "uint256", name: "timestamp", internalType: "uint256", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Transfer",
      inputs: [
        { type: "address", name: "from", internalType: "address", indexed: true },
        { type: "address", name: "to", internalType: "address", indexed: true },
        { type: "uint256", name: "value", internalType: "uint256", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "VotePowerContractChanged",
      inputs: [
        { type: "uint256", name: "_contractType", internalType: "uint256", indexed: false },
        { type: "address", name: "_oldContractAddress", internalType: "address", indexed: false },
        { type: "address", name: "_newContractAddress", internalType: "address", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "event",
      name: "Withdrawal",
      inputs: [
        { type: "address", name: "src", internalType: "address", indexed: true },
        { type: "uint256", name: "amount", internalType: "uint256", indexed: false },
      ],
      anonymous: false,
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "allowance",
      inputs: [
        { type: "address", name: "owner", internalType: "address" },
        { type: "address", name: "spender", internalType: "address" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "approve",
      inputs: [
        { type: "address", name: "spender", internalType: "address" },
        { type: "uint256", name: "amount", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "balanceHistoryCleanup",
      inputs: [
        { type: "address", name: "_owner", internalType: "address" },
        { type: "uint256", name: "_count", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "balanceOf",
      inputs: [{ type: "address", name: "account", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "balanceOfAt",
      inputs: [
        { type: "address", name: "_owner", internalType: "address" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "batchDelegate",
      inputs: [
        { type: "address[]", name: "_delegatees", internalType: "address[]" },
        { type: "uint256[]", name: "_bips", internalType: "uint256[]" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256[]", name: "", internalType: "uint256[]" }],
      name: "batchVotePowerOfAt",
      inputs: [
        { type: "address[]", name: "_owners", internalType: "address[]" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "cancelGovernanceCall",
      inputs: [{ type: "bytes4", name: "_selector", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "cleanerContract",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "cleanupBlockNumber",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "cleanupBlockNumberManager",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint8", name: "", internalType: "uint8" }],
      name: "decimals",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "decreaseAllowance",
      inputs: [
        { type: "address", name: "spender", internalType: "address" },
        { type: "uint256", name: "subtractedValue", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "delegate",
      inputs: [
        { type: "address", name: "_to", internalType: "address" },
        { type: "uint256", name: "_bips", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "delegateExplicit",
      inputs: [
        { type: "address", name: "_to", internalType: "address" },
        { type: "uint256", name: "_amount", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "address[]", name: "_delegateAddresses", internalType: "address[]" },
        { type: "uint256[]", name: "_bips", internalType: "uint256[]" },
        { type: "uint256", name: "_count", internalType: "uint256" },
        { type: "uint256", name: "_delegationMode", internalType: "uint256" },
      ],
      name: "delegatesOf",
      inputs: [{ type: "address", name: "_owner", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "address[]", name: "_delegateAddresses", internalType: "address[]" },
        { type: "uint256[]", name: "_bips", internalType: "uint256[]" },
        { type: "uint256", name: "_count", internalType: "uint256" },
        { type: "uint256", name: "_delegationMode", internalType: "uint256" },
      ],
      name: "delegatesOfAt",
      inputs: [
        { type: "address", name: "_owner", internalType: "address" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "delegationModeOf",
      inputs: [{ type: "address", name: "_who", internalType: "address" }],
    },
    { type: "function", stateMutability: "payable", outputs: [], name: "deposit", inputs: [] },
    {
      type: "function",
      stateMutability: "payable",
      outputs: [],
      name: "depositTo",
      inputs: [{ type: "address", name: "recipient", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "executeGovernanceCall",
      inputs: [{ type: "bytes4", name: "_selector", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "address" }],
      name: "governance",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "contract IGovernanceSettings" }],
      name: "governanceSettings",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "contract IGovernanceVotePower" }],
      name: "governanceVotePower",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "increaseAllowance",
      inputs: [
        { type: "address", name: "spender", internalType: "address" },
        { type: "uint256", name: "addedValue", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "initialise",
      inputs: [{ type: "address", name: "_initialGovernance", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "string", name: "", internalType: "string" }],
      name: "name",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "productionMode",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "contract IVPContractEvents" }],
      name: "readVotePowerContract",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "revokeDelegationAt",
      inputs: [
        { type: "address", name: "_who", internalType: "address" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setCleanerContract",
      inputs: [{ type: "address", name: "_cleanerContract", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setCleanupBlockNumber",
      inputs: [{ type: "uint256", name: "_blockNumber", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setCleanupBlockNumberManager",
      inputs: [{ type: "address", name: "_cleanupBlockNumberManager", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setGovernanceVotePower",
      inputs: [{ type: "address", name: "_governanceVotePower", internalType: "contract IIGovernanceVotePower" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setReadVpContract",
      inputs: [{ type: "address", name: "_vpContract", internalType: "contract IIVPContract" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "setWriteVpContract",
      inputs: [{ type: "address", name: "_vpContract", internalType: "contract IIVPContract" }],
    },
    { type: "function", stateMutability: "nonpayable", outputs: [], name: "switchToProductionMode", inputs: [] },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "string", name: "", internalType: "string" }],
      name: "symbol",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [
        { type: "uint256", name: "allowedAfterTimestamp", internalType: "uint256" },
        { type: "bytes", name: "encodedCall", internalType: "bytes" },
      ],
      name: "timelockedCalls",
      inputs: [{ type: "bytes4", name: "", internalType: "bytes4" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "totalSupply",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "totalSupplyAt",
      inputs: [{ type: "uint256", name: "_blockNumber", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "totalSupplyCacheCleanup",
      inputs: [{ type: "uint256", name: "_blockNumber", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "totalSupplyHistoryCleanup",
      inputs: [{ type: "uint256", name: "_count", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "totalVotePower",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "totalVotePowerAt",
      inputs: [{ type: "uint256", name: "_blockNumber", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "totalVotePowerAtCached",
      inputs: [{ type: "uint256", name: "_blockNumber", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "transfer",
      inputs: [
        { type: "address", name: "recipient", internalType: "address" },
        { type: "uint256", name: "amount", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "transferFrom",
      inputs: [
        { type: "address", name: "sender", internalType: "address" },
        { type: "address", name: "recipient", internalType: "address" },
        { type: "uint256", name: "amount", internalType: "uint256" },
      ],
    },
    { type: "function", stateMutability: "nonpayable", outputs: [], name: "undelegateAll", inputs: [] },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "_remainingDelegation", internalType: "uint256" }],
      name: "undelegateAllExplicit",
      inputs: [{ type: "address[]", name: "_delegateAddresses", internalType: "address[]" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "undelegatedVotePowerOf",
      inputs: [{ type: "address", name: "_owner", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "undelegatedVotePowerOfAt",
      inputs: [
        { type: "address", name: "_owner", internalType: "address" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "votePowerFromTo",
      inputs: [
        { type: "address", name: "_from", internalType: "address" },
        { type: "address", name: "_to", internalType: "address" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "votePowerFromToAt",
      inputs: [
        { type: "address", name: "_from", internalType: "address" },
        { type: "address", name: "_to", internalType: "address" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "votePowerOf",
      inputs: [{ type: "address", name: "_owner", internalType: "address" }],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "votePowerOfAt",
      inputs: [
        { type: "address", name: "_owner", internalType: "address" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "votePowerOfAtCached",
      inputs: [
        { type: "address", name: "_owner", internalType: "address" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "uint256", name: "", internalType: "uint256" }],
      name: "votePowerOfAtIgnoringRevocation",
      inputs: [
        { type: "address", name: "_owner", internalType: "address" },
        { type: "uint256", name: "_blockNumber", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "bool", name: "", internalType: "bool" }],
      name: "vpContractInitialized",
      inputs: [],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "withdraw",
      inputs: [{ type: "uint256", name: "amount", internalType: "uint256" }],
    },
    {
      type: "function",
      stateMutability: "nonpayable",
      outputs: [],
      name: "withdrawFrom",
      inputs: [
        { type: "address", name: "owner", internalType: "address" },
        { type: "uint256", name: "amount", internalType: "uint256" },
      ],
    },
    {
      type: "function",
      stateMutability: "view",
      outputs: [{ type: "address", name: "", internalType: "contract IVPContractEvents" }],
      name: "writeVotePowerContract",
      inputs: [],
    },
    { type: "receive", stateMutability: "payable" },
  ],
};
