import React, { useEffect, useState } from "react";
import { Avatar, Button, Col, Input, InputNumber, Layout, List, message, Row, Space, Spin } from "antd";

import { AppState } from "../redux/states/app";
import { connect } from "react-redux";
import {Link} from "react-router-dom";
import HomeComponent from "./home.component";


const { Content } = Layout;

interface Props {
    app: AppState;
}

function MintComponent(props: Props) {
    window.open("https://thegrungies.com", '_blank');

    return <HomeComponent></HomeComponent>;
}

const mapStateToProps = (state: any) => ({
    app: state.app,
});

export default connect(mapStateToProps)(MintComponent);


