import { contracts } from "../contracts";
import { Contract } from "ethers";

export const getRewardsData = async (provider: any, network: "songbird" | "flare", account: string) => {
  const epochData: { epochId: number; amount: number }[] = [];
  let currentRewards = 0;

  if (provider) {
    const signer = provider.getSigner();
    try {
      const ftsoManager = contracts[network].ftsoManager;
      const ftsoRewardManager = contracts[network].ftsoRewardManager;
      const ftsoManagerContract = new Contract(ftsoManager.address, ftsoManager.abi, provider);
      const rewardManagerAddress = await ftsoManagerContract.rewardManager();
      const ftsoRewardManagerContract = new Contract(rewardManagerAddress, ftsoRewardManager.abi, signer);
      const currentRewardEpoch = await ftsoManagerContract.getCurrentRewardEpoch();
      const currentRewardEpochValue = currentRewardEpoch.toNumber();

      const epochs = await ftsoRewardManagerContract.getEpochsWithUnclaimedRewards(account);
      const rewardAmountCurrentEpoch = await ftsoRewardManagerContract.getStateOfRewards(account, currentRewardEpochValue);

      for (const epoch of epochs) {
        const rewards = await ftsoRewardManagerContract.getStateOfRewards(account, epoch);

        const totalAmount = rewards._rewardAmounts.reduce((a: number, b: string) => parseInt(b) / 10e17 + a, 0);
        epochData.push({ epochId: epoch.toNumber(), amount: totalAmount });
      }

      if (rewardAmountCurrentEpoch._rewardAmounts.length > 0) {
        const rewardAmountCurrentEpochValue = parseInt(rewardAmountCurrentEpoch._rewardAmounts[0].toString()) / 10e17;
        epochData.push({ epochId: currentRewardEpochValue, amount: rewardAmountCurrentEpochValue });
        currentRewards = rewardAmountCurrentEpochValue;
      }
    } catch (e) {}
  }

  return { pendingRewards: epochData, currentRewards: currentRewards };
};
